import { ICard } from '../components/Card';
import { IAsset } from '../types';

const getSkeletonCards = (): ICard[] => {
  const emptyCard: ICard = {
    id: '',
    title: '',
    hash: '',
    price: '',
    loading: true,
    devikinData: {} as IAsset,
  };

  const size = 12;
  const list: ICard[] = [];

  for (let i = 0; i < size; i++) {
    list.push(emptyCard);
  }

  return list;
};

const defaultDevikin: IAsset = {
  _id: '613f89a283bb737ac4468d39',
  Hash: '449cdae5bd67804825ae1615c91f064359a1421270fbcd45a2cb9b7962d0cf92',
  ParentHashList: [
    '30a7b9d2710e3361ad910de440c270ec7091387569f004fa6c9a0c31c45cca65',
  ],
  UserId: 'THHjFEmYb5VCqaFAq8KrKvaqEQ7pJsbWDr',
  Eyes: {
    id: '612e95456d1b9f0811fcd24f',
    Name: 'Doitto',
    Path: 'any/eyes/010/main',
    Category: 0,
    Tag: 0,
    Ancestry: 0,
    ReleaseStream: 'Release001',
    GeneId: '010',
    Weight: 1,
    DateCreated: '2021-08-31T20:46:59Z',
  },
  Mouth: {
    id: '612e95456d1b9f0811fcd25a',
    Name: 'Dully',
    Path: 'purple/mouth/001/main',
    Category: 1,
    Tag: 0,
    Ancestry: 5,
    ReleaseStream: 'Release001',
    GeneId: '001',
    Weight: 1,
    DateCreated: '2021-08-31T20:46:59Z',
  },
  Hair: {
    id: '612e95456d1b9f0811fcd2f0',
    Name: 'Doddy',
    Path: 'any/hair/003/main',
    Category: 3,
    Tag: 0,
    Ancestry: 0,
    ReleaseStream: 'Release001',
    GeneId: '003',
    Weight: 1,
    DateCreated: '2021-08-31T20:46:59Z',
  },
  Head: {
    id: '612e95456d1b9f0811fcd35d',
    Name: 'Head005',
    Path: 'purple/head/005/main',
    Category: 7,
    Tag: 0,
    Ancestry: 5,
    ReleaseStream: 'Release001',
    GeneId: '005',
    Weight: 1,
    DateCreated: '2021-08-31T20:46:59Z',
  },
  Ears: {
    id: '612e95456d1b9f0811fcd2c3',
    Name: 'Pierced Gablan',
    Path: 'purple/ears/007/main',
    Category: 2,
    Tag: 1,
    Ancestry: 5,
    ReleaseStream: 'Release001',
    GeneId: '007',
    Weight: 1,
    DateCreated: '2021-08-31T20:46:59Z',
  },
  Horns: {
    id: '612e95456d1b9f0811fcd320',
    Name: 'Broekn dimen',
    Path: 'any/horns/005/main',
    Category: 4,
    Tag: 1,
    Ancestry: 0,
    ReleaseStream: 'Release001',
    GeneId: '005',
    Weight: 1,
    DateCreated: '2021-08-31T20:46:59Z',
  },
  Outfit: {
    id: '612e95456d1b9f0811fcd337',
    Name: 'OutfitAngry',
    Path: 'any/outfit/personality/angry',
    Category: 6,
    Tag: 0,
    Ancestry: 0,
    ReleaseStream: 'Release001',
    GeneId: '001',
    Weight: 1,
    DateCreated: '2021-08-31T20:46:59Z',
  },
  AncestryType: 5,
  Personality: 1,
  VitAffinity: 3,
  PowAffinity: 8,
  ForAffinity: 4,
  AgiAffinity: 6,
  SanAffinity: 9,
  OverallAffinity: 30,
  Rarity: 1,
  VitBase: 45,
  PowBase: 40,
  ForBase: 57,
  AgiBase: 66,
  SanBase: 69,
  IsSellable: true,
  Stage: 4,
  Status: 0,
  SequenceCounter: 2,
  SystemVersion: '0.0.4',
  IsHardMinted: false,
  DateCreated: '2021-09-13T17:25:54Z',
};

export { getSkeletonCards, defaultDevikin };
