import axios from 'axios';

const { REACT_APP_INVENTORY_API, REACT_APP_SHOP_API, REACT_APP_SHOP_API_KEY } =
  process.env;

const defaultHeaders = { 'Content-Type': 'application/json' };

const shopHeaders = { 'api-key': REACT_APP_SHOP_API_KEY };

const createDevikinBaseURL = 'https://img-creator.devikins.com/';
const statusDevikinsURL =
  'https://emvos9qmx7.execute-api.us-east-1.amazonaws.com/';
const managerExchangeKleverBaseURL =
  'https://manager.exchange.klever.io/v1/keypairs?ID=52';

const inventoryApi = axios.create({
  baseURL: `${REACT_APP_INVENTORY_API}`,
  headers: { ...defaultHeaders },
});

const shopApi = axios.create({
  baseURL: `${REACT_APP_SHOP_API}`,
  headers: { ...defaultHeaders, ...shopHeaders },
});

const creatorDevikinsApi = axios.create({
  baseURL: createDevikinBaseURL,
});

const statusDevikinsApi = axios.create({
  baseURL: statusDevikinsURL,
  headers: {
    ...defaultHeaders,
    'Access-Control-Allow-Headers':
      'Origin, X-Requested-With, Content-Type, Accept',
  },
});

const managerExchangeKlever = axios.create({
  baseURL: managerExchangeKleverBaseURL,
  headers: { ...defaultHeaders },
});

export {
  inventoryApi,
  shopApi,
  creatorDevikinsApi,
  statusDevikinsApi,
  managerExchangeKlever,
};
