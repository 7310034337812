import styled, { css } from 'styled-components';

import { transparentize } from 'polished';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  margin: 0 5rem;
  padding-top: 2.5rem;

  @media (max-width: 1024px) {
    margin: 0 1.5rem;
    padding-top: 0;
  }
`;

export const ViewerContainer = styled.div`
  display: flex;

  flex-direction: row;

  gap: 2.5rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const AssetContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;

    align-items: center;
  }
`;

export const AssetInfoContainer = styled.div`
  width: 100%;

  display: flex;

  flex-direction: row;

  align-items: center;
  justify-content: center;
`;

export const AssetInfoContent = styled.div`
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  margin: 0 2vw;

  span {
    color: ${props => transparentize(0.3, props.theme.text)};
  }

  p {
    font-weight: 500;
    color: ${props => props.theme.text};

    transition: 0.2s ease;

    cursor: pointer;

    &:hover {
      color: ${props => props.theme.primary};
    }
  }
`;

export const AssetPaginationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 5px;
`;

export const AttributesContainer = styled.div``;

export const AttributesTitle = styled.div`
  margin-bottom: 2.5rem;

  h1 {
    color: ${props => props.theme.text};
    font-weight: 600;
  }

  span {
    color: ${props => transparentize(0.3, props.theme.text)};
  }

  div.minted {
    size: 8px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
  }

  @media (max-width: 768px) {
    display: flex;

    flex-direction: column;

    align-items: center;
  }
`;

export const AttributesContent = styled.div`
  display: flex;

  flex-direction: column;

  h3 {
    margin-bottom: 0.75rem;

    position: relative;

    color: ${props => props.theme.text};
    font-weight: 500;

    &::before {
      height: 1px;
      width: 100%;

      top: 1.5rem;
      left: 0;

      position: absolute;
      content: '';

      background-color: ${props => props.theme.card.border};
    }
  }

  &:not(:first-child) {
    margin-top: 2.5rem;
  }
`;

export const AttributesDetails = styled.div<{ procreation: boolean }>`
  display: flex;

  flex-direction: row !important;

  gap: 1.25rem;

  align-items: flex-start;

  flex-wrap: wrap;

  img {
    height: 2.75rem;
    width: auto;
  }

  div {
    display: flex;

    flex-direction: row;

    align-items: center;

    gap: 0.25rem;
  }

  @media screen and (max-width: 392px) {
    flex-direction: ${props =>
      props.procreation ? 'column-reverse !important' : 'initial'};
  }
`;

const colors = css<{ color: string }>`
  padding: 0.25rem 0.5rem;

  background-color: ${props => props.color};

  font-size: 0.875rem;
  color: ${props =>
    props.color === '#FFE600' || props.color === '#EAEAEA'
      ? props.theme.card.collection
      : props.theme.white};

  border-radius: 0.5rem;
`;

export const AttributesDetailsContent = styled.div<{ color: string }>`
  flex-direction: column !important;

  align-items: flex-start !important;

  gap: 0.25rem !important;

  span {
    color: ${props => transparentize(0.3, props.theme.text)};
  }

  p {
    color: ${props => props.theme.text};

    ${props => props.color !== 'transparent' && colors};
  }
`;

export const SpanHardMint = styled.div<{ color: string }>`
  flex-direction: column !important;
`;

export const Input = styled.input`
  margin: 0.4rem 0.4rem;
  background-color: ${props => props.theme.secondary};
  padding: 0.4rem 0.4rem;
  border-radius: 12px;
  border: 2px solid transparent;
  background: rgba(228, 228, 228, 0.3);
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #11142d;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
`;

export const Div = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
`;

export const Button = styled.button`
  margin: 1rem 0.4rem;
  padding: 0.25rem 0.5rem;

  display: flex;

  align-items: center;
  justify-content: center;

  background-color: ${props => props.theme.button.background};

  border-radius: 0.3rem;

  color: ${props => props.theme.white};

  cursor: pointer;

  transition: 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.button.hoverBackground};

    color: ${props => props.theme.text};
  }
`;

export const Paragraph = styled.p`
  display: flex;
`;

export const ParentsLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    height: 200px;
    max-height: 100%;
    padding-bottom: 5px;
  }

  &:hover {
    color: ${props => props.theme.primary};
  }

  &:last-child {
    float: right;
    margin-left: 5px;
  }
`;
