import { inventoryApi } from '../../services/api';
import { Inventory, ReduxAction, IFilters } from '../types';
import get from 'lodash/get';
import { IAsset } from '../../types';
import { getNftsSoftLocalStorage, setNftsSoftLocalStorage } from '../../utils';

export const Types = {
  SET_PAGE: 'inventory/SET_PAGE',
  SET_FILTERS: 'inventory/SET_FILTERS',
  SET_ASSETS: 'inventory/SET_ASSETS',
  SET_ASSETS_SOFT_MINT: 'inventory/SET_ASSETS_SOFT_MINT',
  SET_REVEALED: 'inventory/SET_REVEALED',
  SET_PAGINATION: 'inventory/SET_PAGINATION',
  SET_PAGINATION_SOFT_MINT: 'inventory/SET_PAGINATION_SOFT_MINT',
  LOADING: 'inventory/LOADING',
  FAILURE: 'inventory/FAILURE',
};

const initialState: Inventory = {
  assets: [],
  devikinsSelecteds: [],
  assetsSoftMint: [],
  allRevealed: true,
  pagination: {
    totalPage: 0,
    totalItems: 0,
    page: 1,
  },
  paginationSofitMint: {
    totalPage: 0,
    totalItems: 0,
    page: 1,
  },
  failed: false,
  loading: false,
  filters: {
    ancestry: '',
    ordAffinity: '',
    ordCreated: '',
    ordSequence: '',
    personality: '',
    rarity: '',
  },
};

export const verifyIsPending = (assets?: IAsset) => {
  if (assets?.length > 0 && assets) {
    const nftsSoftmint = JSON.parse(getNftsSoftLocalStorage() || '[]');
    if (nftsSoftmint?.length > 0) {
      const notIsPending = nftsSoftmint.filter(
        (nft: number) =>
          nft ===
          assets.find(
            (asset: IAsset) =>
              nft === asset?.SequenceCounter && asset?.IsHardMinted,
          )?.SequenceCounter,
      );

      let newsPending: number[] = nftsSoftmint;
      notIsPending.forEach((item: number) => {
        newsPending = newsPending.filter((newItem: number) => item !== newItem);
      });
      if (newsPending?.length > 0) {
        setNftsSoftLocalStorage(JSON.stringify(newsPending));
        return;
      }
      return;
    }
    return;
  }

  return;
};

export const fetchAssets = (url: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch({ type: Types.LOADING });
      const inventory = await inventoryApi.get(url);
      const assets = get(inventory, 'data.Assets', []);
      const totalItems = get(inventory, 'data.total', 0);
      const page = get(inventory, 'data.page', 1);

      verifyIsPending(assets);

      if (assets === null && page > 1) {
        dispatch({
          type: Types.SET_PAGE,
          payload: 1,
        });
        return;
      }
      dispatch({
        type: Types.SET_ASSETS,
        payload: assets,
      });
      dispatch({
        type: Types.SET_PAGINATION,
        payload: {
          totalPage: get(inventory, 'data.totalPage', 0),
          totalItems,
          page,
        },
      });
    } catch (error) {
      dispatch({ type: Types.FAILURE });
    }
  };
};

export const fetchAssetsSoftMint = (url: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch({ type: Types.LOADING });
      const inventory = await inventoryApi.get(url);
      const assets = get(inventory, 'data.Assets', []);
      verifyIsPending(assets);

      dispatch({
        type: Types.SET_ASSETS_SOFT_MINT,
        payload: assets || [],
      });

      dispatch({
        type: Types.SET_PAGINATION_SOFT_MINT,
        payload: {
          totalPage: get(inventory, 'data.totalPage', 0),
          totalItems: get(inventory, 'data.total', 0),
          page: get(inventory, 'data.page', 1),
        },
      });
    } catch (error) {
      dispatch({ type: Types.FAILURE });
    }
  };
};

export const setPage = (page: number) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: Types.SET_PAGE,
        payload: page,
      });
    } catch (error) {
      dispatch({ type: Types.FAILURE });
    }
  };
};

export const fetchPrevAssets = (url: string) => {
  return async (dispatch: Function, getStore: Function) => {
    try {
      const { inventory } = getStore();
      const { assets } = inventory;
      const data = await inventoryApi.get(url);
      const items = get(data, 'data.Assets', []);
      verifyIsPending(items);
      if (!(assets.findIndex((a: any) => a._id === items[0]?._id) >= 0)) {
        dispatch({
          type: Types.SET_ASSETS,
          payload: [...items, ...assets],
        });
        dispatch({
          type: Types.SET_PAGINATION,
          payload: {
            totalPage: get(data, 'data.totalPage', 0),
            totalItems: get(data, 'data.total', 0),
            page: get(data, 'data.page', 1),
          },
        });
      }
    } catch (error) {
      dispatch({ type: Types.FAILURE });
    }
  };
};

export const fetchNextAssets = (url: string) => {
  return async (dispatch: Function, getStore: Function) => {
    try {
      const { inventory } = getStore();
      const { assets } = inventory;
      const data = await inventoryApi.get(url);
      const items = get(data, 'data.Assets', []);
      verifyIsPending(items);
      if (!(assets.findIndex((a: any) => a._id === items[0]?._id) >= 0)) {
        dispatch({
          type: Types.SET_ASSETS,
          payload: [...assets, ...items],
        });
        dispatch({
          type: Types.SET_PAGINATION,
          payload: {
            totalPage: get(data, 'data.totalPage', 0),
            totalItems: get(data, 'data.total', 0),
            page: get(data, 'data.page', 1),
          },
        });
      }
    } catch (error) {
      dispatch({ type: Types.FAILURE });
    }
  };
};

export const fetchRevealed = () => {
  return async (dispatch: Function) => {
    try {
      dispatch({ type: Types.LOADING });
      const result = await inventoryApi.get('/all-revealed');
      dispatch({
        type: Types.SET_REVEALED,
        payload: get(result, 'data.isAllRevealed', false),
      });
    } catch (error) {
      dispatch({ type: Types.FAILURE });
    }
  };
};

export const revealAll = () => {
  return async (dispatch: Function) => {
    try {
      const result = await inventoryApi.post('/reveal', { all: true });
      dispatch({
        type: Types.SET_REVEALED,
        payload: get(result, 'data.isAllRevealed', false),
      });
    } catch (error) {
      dispatch({ type: Types.FAILURE });
    }
  };
};

export const resetInventoryPagination = () => {
  return (dispatch: Function) => {
    try {
      dispatch({
        type: Types.SET_PAGINATION,
        payload: {
          page: 1,
          totalPage: 0,
          totalItems: 1,
        },
      });
    } catch (error) {
      dispatch({ type: Types.FAILURE });
    }
  };
};

export const setFilters = (filters: IFilters) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: Types.SET_FILTERS,
        payload: filters,
      });
    } catch (error) {
      dispatch({ type: Types.FAILURE });
    }
  };
};

export const clearFilters = () => {
  return (dispatch: Function) => {
    try {
      dispatch({
        type: Types.SET_FILTERS,
        payload: initialState.filters,
      });
    } catch (error) {
      dispatch({ type: Types.FAILURE });
    }
  };
};

const reducer = (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case 'inventory/SET_ASSETS':
      return {
        ...state,
        assets: action.payload,
        loading: false,
        failed: false,
      };
    case 'inventory/SET_ASSETS_SOFT_MINT':
      return {
        ...state,
        assetsSoftMint: action.payload,
        loading: false,
        failed: false,
      };
    case 'inventory/SET_FILTERS':
      return { ...state, filters: action.payload };
    case 'inventory/SET_PAGE':
      return { ...state, pagination: { page: action.payload } };
    case 'inventory/SET_REVEALED':
      return { ...state, allRevealed: action.payload, loading: false };
    case 'inventory/SET_PAGINATION':
      return { ...state, pagination: action.payload };
    case 'inventory/SET_PAGINATION_SOFT_MINT':
      return { ...state, paginationSofitMint: action.payload };
    case 'inventory/LOADING':
      return { ...state, loading: true };
    case 'inventory/FAILURE':
      return { ...state, loading: false, failed: true };
    default:
      return state;
  }
};

export default reducer;
