import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;

  display: flex;

  flex-direction: row;

  align-items: center;

  cursor: pointer;

  span {
    text-transform: capitalize;
    color: ${props => props.theme.text};

    transition: 0.2s ease;
  }

  &:hover {
    span {
      color: ${props => props.theme.primary};
    }
  }
`;

export const Icon = styled.div<{ isOpen: boolean }>`
  margin: 0 0.5rem;

  display: flex;

  align-items: center;
  justify-content: center;

  opacity: 0.7;

  transition: 0.3s ease;

  ${props =>
    props.isOpen &&
    css`
      transform: rotate(180deg);
    `}

  svg {
    font-size: 0.875rem;

    color: ${props => props.theme.text};
  }
`;

const contentHeight = 10;
const minContentHeight = 7.85;
export const Content = styled.div<{ isOpen: boolean; itemSize: number }>`
  width: fit-content;
  max-height: ${props =>
    props.itemSize < 4 ? minContentHeight : contentHeight}rem;

  overflow-y: auto;

  padding: 0.5rem;

  left: 0;
  bottom: -${props => () => props.itemSize < 4 ? minContentHeight : contentHeight + 0.25}rem;

  position: absolute;
  display: flex;

  flex-direction: column;

  gap: 0.75rem;

  z-index: 10;

  opacity: ${props => (props.isOpen ? 1 : 0)};
  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};

  background-color: ${props => props.theme.background};

  border: 1px solid ${props => props.theme.header.border};
  border-radius: 0.5rem;

  transition: 0.2s ease;

  span {
    padding: 0.25rem 4rem 0.25rem 0;

    color: ${props => transparentize(0.3, props.theme.text)} !important;

    &:hover {
      color: ${props => props.theme.text} !important;
    }
  }
`;
