import React, { useCallback, useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';

import {
  Container,
  Content,
  Title,
  ActionContainer,
  LoaderContainer,
  Div,
  Col,
  AddressContainer,
  AddressHeader,
  AddressDivider,
  AddressBody,
  AddressFooter,
  AddressInput,
  Input,
} from './styles';

import Card, { ICard } from '../../components/Card';
import CardWithdraw from '../../components/CardWithdraw';
import Modal, { IModal } from '../../components/Modal';
import Button, { IButton } from '../../components/Button';
import Dropdown, { IDropdown } from '../../components/Filter/Dropdown';
import Loader from '../../components/Loader';
import Filter from '../../components/Filter';
import { NotFound } from '..';

import {
  fetchAssets,
  fetchRevealed,
  revealAll,
  setFilters,
  clearFilters,
} from '../../store/reducers/invetory';
import { fetchlistNextAndPrev } from '../../store/reducers/nextAndPrev';

import { Ancestry, IAsset, Personality, Rarity } from '../../types';
import { getTitle } from '../../utils';
import { getSkeletonCards } from '../../mocks';
import {
  ancestryDropdown,
  ordAffinity,
  ordCreated,
  personalityDropdown,
  rarityDropdown,
} from '../../config/inventory';

import { RevealProvider } from '../../hooks/Reveal';

import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { useHistory } from 'react-router';
import { useTron } from '../../hooks/Tron';
import { ApplicationState } from '../../store/types';
import { Link } from 'react-router-dom';
import { useToast } from '../../hooks/Toast';

interface IDevikin {
  _id: string;
}

interface IDevikin {
  _id: string;
}
interface IAllFilters {
  limit: number;
  page: number;
  rarity?: string;
  ancestryType?: string;
  personality?: string;
  [key: string]: any;
}

const Inventory: React.FC<any> = ({
  fetchAssets,
  fetchRevealed,
  assets,
  allRevealed,
  loading,
  totalPage,
  totalItems,
  page,
  setFilters,
  clearFilters,
  filters,
}) => {
  document.title = getTitle('Inventory');

  const { getMinterResources } = useTron();
  const { getNFTContractResources } = useTron();

  const modalRef = useRef<IModal>(null);
  const history = useHistory();
  const { address, transferFrom } = useTron();
  const { addToast } = useToast();

  const [data, setData] = useState<ICard[]>([]);
  const [canMint, setCanMint] = useState(false);
  const [canTransfer, setCanTransfer] = useState(false);
  const [resourceAmount, setResourceAmount] = useState('');
  const [selectedToWitdraw, setSelectedToWitdraw] = useState(false);
  const [toAddress, setToAddress] = useState('');
  const [devikinsSelecteds, setDevikinsSelecteds] = useState<number[]>([]);

  const limit = 12;

  const title = useCallback(() => {
    return `Inventory `;
  }, []);

  const totalCount = useCallback(() => {
    return `Total ${totalItems || 0} NFT${totalItems > 1 ? 's' : ''}`;
  }, [totalItems]);

  const isInitialFilters = useCallback(() => {
    return Object.values(filters).every(x => x === null || x === '');
  }, [filters]);

  const getUrl = useCallback(
    (page: number) => {
      let filterState = {};
      const getIndex = (key: string, val: any) => {
        const filter = filters[key];

        let index = -1;

        const filtered = Object.values(val).filter(
          item => typeof item === 'string',
        );

        filtered.forEach((item, i) => {
          if (String(item).toLocaleLowerCase() === filter) {
            index = i;
          }
        });

        if (index !== -1) {
          filterState = { ...filterState, [key]: index };
        }
      };

      const indexesKeys = [
        { key: 'rarity', val: Rarity },
        { key: 'ancestryType', val: Ancestry },
        { key: 'personality', val: Personality },
      ];
      const ordersKeys = ['ordAffinity', 'ordCreated'];

      indexesKeys.forEach(item => getIndex(item.key, item.val));

      ordersKeys.forEach(item => {
        if (filters[item] !== '') {
          let type = filters[item].includes('asc') ? 'asc' : 'desc';

          filterState = { ...filterState, [item]: type };
        }
      });

      const allFilters: IAllFilters = { limit, page, ...filterState };

      const convertParams = Object.keys(allFilters).map(
        key => `${key}=${allFilters[key]}`,
      );

      return `/inventory?${convertParams.join('&')}`;
    },
    [filters],
  );

  const fetchData = useCallback(
    async ({ page }: any) => {
      fetchAssets(getUrl(page));
    },
    [fetchAssets, getUrl],
  );

  const getCards = (): ICard[] => (loading ? getSkeletonCards() : data);

  const onPageChange = async ({ selected: page }: any) => {
    await fetchData({ page: page + 1 });
  };

  const revealButtonProps: IButton = {
    name: allRevealed ? 'All Devikins are Revealed!' : 'Reveal All',
    disabled: allRevealed,
    onClick: revealAll,
  };

  const paginateProps: ReactPaginateProps = {
    previousLabel: <FiArrowLeft />,
    nextLabel: <FiArrowRight />,
    breakLabel: '...',
    pageRangeDisplayed: 3,
    marginPagesDisplayed: 2,
    pageCount: totalPage,
    activeClassName: 'paginate-active',
    containerClassName: 'paginate-pagination',
    onPageChange,
    forcePage: page - 1,
  };

  const notFoundProps = {
    code: ' ',
    title: 'Empty inventory',
    subtitle: `Oops, it looks like you do not have any Devikin.`,
  };

  const modalProps = { ref: modalRef, forReveal: true };
  const modalAddressProps = { ref: modalRef, forReveal: true };

  const getDropdownProps = useCallback(
    (props: IDropdown) => {
      return {
        ...props,
        filters,
        setFilters,
      };
    },
    [setFilters, filters],
  );

  const filterProps = {
    filterHandle: async () => {
      await clearFilters();
    },
    btnDisabled: isInitialFilters() ? true : false,
    disabled: isInitialFilters() && data.length === 0,
  };

  function handleDevikins(devikin: IAsset) {
    let listDevikins = [];
    if (
      devikinsSelecteds.filter(d => d === devikin.SequenceCounter).length > 0
    ) {
      listDevikins = devikinsSelecteds.filter(
        d => d !== devikin?.SequenceCounter,
      );
      setDevikinsSelecteds(listDevikins);
    } else {
      if (devikinsSelecteds?.length >= 10) {
        addToast({
          type: 'info',
          title: '10 nfts already selected!',
        });
        return;
      }
      listDevikins = [...devikinsSelecteds, devikin.SequenceCounter];
      setDevikinsSelecteds(listDevikins);
    }
    return;
  }

  const isSelected = useCallback(
    (devikin: ICard) => {
      const result =
        devikinsSelecteds.findIndex(function (d: number) {
          return d === devikin.devikinData.SequenceCounter;
        }) >= 0
          ? true
          : false;
      return result;
    },
    [devikinsSelecteds],
  );

  useEffect(() => {
    const checkMinter = async () => {
      const { energy, bandwidth } = await getMinterResources();
      let checkMint = energy > 200_000_000 && bandwidth > 150_000;

      setCanMint(checkMint);
    };

    checkMinter();
  }, [getMinterResources]);

  useEffect(() => {
    const checkNFTContract = async () => {
      const { energy, bandwidth } = await getNFTContractResources();
      let checkMint = true; /*energy > 5_000_000 && bandwidth > 150_000;*/

      let amount = (energy/1_000_000).toFixed(2) + "M / 5M";  
      setResourceAmount(amount);
      setCanTransfer(checkMint);
    };

    checkNFTContract();
  }, [getNFTContractResources]);

  useEffect(() => {
    if (!address) {
      history.push('/');
    }
    fetchlistNextAndPrev('', address);
    fetchRevealed();

    window.scrollTo(0, 0);
  }, [fetchRevealed, address, history, page]);

  useEffect(() => {
    let newData: ICard[] = [];

    if (assets?.length) {
      newData = assets?.map((asset: IAsset) => {
        return {
          id: asset._id,
          title: `#${asset.SequenceCounter}`,
          hash: asset.Hash,
          price: 'N/A',
          devikinData: asset,
          modalRef,
        };
      });
    }
    setData(newData);
  }, [assets]);

  const mintButtonProps: IButton = {
    name: !canMint ? 'Recharging' : 'Mint ',
    disabled: !canMint,
  };

  const _verifyAddress = useCallback(async () => {
    if (!toAddress || toAddress?.length < 33) {
      return addToast({
        type: 'info',
        title: 'Add a valid address',
        description: 'It must be a TRON address!',
      });
    }
    if (toAddress === address) {
      return addToast({
        type: 'info',
        title: 'You cannot make a withdrawal for yourself',
      });
    }

    await transferFrom(toAddress, devikinsSelecteds)
    setDevikinsSelecteds([])
    setToAddress('')
    modalRef.current?.handle()
  }, [toAddress, devikinsSelecteds, addToast, address, transferFrom])

  const comfirmButtonProps: IButton = {
    name: 'Confirm',
    onClick: () => _verifyAddress(),
  };

  const paste = useCallback(async () => {
    const text = await navigator.clipboard.readText();
    setToAddress(text);
  }, []);

  const pastButtonProps: IButton = {
    name: 'Paste',
    onClick: () => paste(),
    style: { padding: '0.25rem 0.5rem', margin: '0.3rem 0.4rem' },
  };

  const withdrawSendButtonProps: IButton = {
    name: `Withdraw ${devikinsSelecteds?.length > 0
      ? `selected (${devikinsSelecteds?.length})`
      : ''
      }`,
    onClick: () => modalRef.current?.handle(),
    disabled: devikinsSelecteds?.length === 0,
  };

  const withdrawButtonProps: IButton = {
    name: !canTransfer ? 'Transfer Energy Recharging: ' + resourceAmount : `${selectedToWitdraw ? 'Inventory' : 'Withdraw Each For 75,000 Energy (32 TRX)'}`,
    disabled: !canTransfer,
    onClick: () => setSelectedToWitdraw(!selectedToWitdraw),
  };

  useEffect(() => {
    fetchData({ page });
  }, [fetchData, filters, page]);

  return (
    <Container>
      <RevealProvider>
        <Modal {...modalProps} />
        <Modal {...modalAddressProps}>
          <AddressContainer>
            <AddressHeader>
              <span>Withdraw</span>
            </AddressHeader>
            <AddressDivider />
            <AddressBody>
              <span>
                It must be a TRON address.
              </span>
              <span style={{color : "#f55"}}>
                Do not transfer to Bitcoin.me Marketplace, it only supports Devikin KDA.
                Use Devikins game to convert TRON NFT to KDA.
              </span>
              <p>Address</p>
              <AddressInput>
                <Input
                  type="text"
                  placeholder="Address"
                  value={toAddress}
                  autoFocus
                  onChange={e => setToAddress(e.target.value)}
                />
                <Button {...pastButtonProps}>Paste</Button>
              </AddressInput>
              <span>
                Tron withdraw network fee for each NFT is: <span style={{color : "#ff0"}}>75,000 Energy (32 TRX)</span>. Please, make sure you have resources available. <span style={{color : "#f55"}}>If not, transactions will fail.</span> 
              </span>
            </AddressBody >
            <AddressFooter>
              <Button {...comfirmButtonProps} />
            </AddressFooter>
          </AddressContainer >
        </Modal >
        <Title>
          <h3>{title()}</h3>
        </Title>
        <Div>
          <Div>
            <ActionContainer>
              <Button {...revealButtonProps} />
            </ActionContainer>
          </Div>
          <Col>
            <h3>{totalCount()}</h3>
          </Col>
          <Div>
            {/*!selectedToWitdraw && (
              <>
                {canMint ? (
                  <Link to="/shop/softmint/inventory">
                    <Button {...mintButtonProps} />
                  </Link>
                ) : (
                  <Link to="#">
                    <Button {...mintButtonProps} />
                  </Link>
                )}
              </>
                )*/}
            {canTransfer ? (
                  <Link to="#" className="klever-button">
                  <Button {...withdrawButtonProps} />
                </Link>
                ) : (
                  <Link to="#">
                  <Button {...withdrawButtonProps} />
                  </Link>
                )}            
            {selectedToWitdraw && (
              <Link to="#">
                <Button {...withdrawSendButtonProps} />
              </Link>
            )}
          </Div>
        </Div>
        <Filter {...filterProps}>
          {/* <Search /> */}
          <Dropdown {...getDropdownProps(rarityDropdown)} />
          <Dropdown {...getDropdownProps(ancestryDropdown)} />
          <Dropdown {...getDropdownProps(personalityDropdown)} />
          <Dropdown {...getDropdownProps(ordAffinity)} />
          <Dropdown {...getDropdownProps(ordCreated)} />
        </Filter>
        {
          data?.length === 0 && !loading ? (
            <NotFound {...notFoundProps} />
          ) : (
            <>
              {loading && (
                <LoaderContainer>
                  <Loader size={3.5} />
                </LoaderContainer>
              )}
              <Content dataSize={loading ? 0 : getCards().length}>
                {!loading &&
                  getCards().map((card, index) =>
                    !selectedToWitdraw ? (
                      <Card key={index} {...card} />
                    ) : (
                      <CardWithdraw
                        isSelected={isSelected(card)}
                        onClick={handleDevikins}
                        key={index}
                        {...card}
                      />
                    ),
                  )}
              </Content>
            </>
          )
        }
        {totalItems > 0 && <ReactPaginate {...paginateProps} />}
      </RevealProvider >
    </Container >
  );
};

const mapState = (state: ApplicationState) => {
  return {
    assets: state?.inventory?.assets,
    allRevealed: state?.inventory?.allRevealed,
    loading: state?.inventory?.loading,
    totalPage: state?.inventory?.pagination?.totalPage,
    totalItems: state?.inventory?.pagination?.totalItems,
    page: state?.inventory?.pagination?.page,
    filters: state?.inventory?.filters,
  };
};

const mapDispatch = (dispatch: any) =>
  bindActionCreators(
    {
      fetchAssets,
      fetchRevealed,
      revealAll,
      setFilters,
      clearFilters,
    },
    dispatch,
  );

export default connect(mapState, mapDispatch)(Inventory);
