import styled, { css } from 'styled-components';

import { darken } from 'polished';

import { IButton } from '.';

const disabled = css`
  background-color: ${props => darken(0.25, props.theme.button.background)};

  color: ${props => darken(0.05, props.theme.white)};

  cursor: not-allowed;

  &:hover {
    background-color: ${props =>
      darken(0.2, props.theme.button.background)} !important;

    color: ${props => props.theme.white} !important;
  }
`;

const mobile = css`
  display: none;
`;

export const Container = styled.div<IButton>`
  padding: 0.75rem 1.25rem;

  display: flex;

  align-items: center;
  justify-content: center;

  background-color: ${props => props.theme.button.background};

  border-radius: 0.5rem;

  color: ${props => props.theme.white};

  cursor: pointer;

  transition: 0.3s ease;

  ${props => props.disabled && disabled};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${props => props.theme.button.hoverBackground};

      color: ${props => props.theme.text};
    }
  }

  @media (max-width: 425px) {
    ${props => props.isMobile && mobile};
  }
`;
