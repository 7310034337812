

import React, {
  useEffect,
  useCallback,
  useState,
} from 'react';

import Typewriter from 'typewriter-effect';

import {
  Container,
  ListContainer,
  ListContent,
  ListTitle,
  TitleContainer,
  TitleContent,
  RenderStatus,
  ItemStatus,
  CollectionStats
} from './styles';

import Card, { ICard } from '../../components/Card';

import { slogan, subtitle, writerStrings, listTitle } from '../../config/home';

import { getSkeletonCards } from '../../mocks';
import { inventoryApi } from '../../services/api';
import { IAsset } from '../../types';
import { formatInteger } from '../../utils/';

import { RevealProvider } from '../../hooks/Reveal';
import { ApplicationState } from '../../store/types';
import { fetchStatus } from '../../store/reducers/status';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const Home: React.FC<any> = ({ status, fetchStatus }) => {
  const [loading, setLoading] = useState(true);
  const [latest, setLatest] = useState<ICard[]>([]);

  const limit = 12;

  const fetchData = useCallback(async () => {
    const response = await inventoryApi.get(`/latest?limit=${limit}`);

    const newData: ICard[] = response.data.Assets.map((asset: IAsset) => ({
      id: asset._id,
      title: `#${asset.SequenceCounter}`,
      hash: asset.Hash,
      price: 'N/A',
      available: {
        total: 1,
        bought: 1,
      },
      devikinData: asset,
    }));

    setLatest(newData);

    if (loading) {
      setLoading(false);
    }
  }, [loading])

  useEffect(() => {
    fetchData();

    fetchStatus()
    window.scrollTo(0, 0);
  }, [fetchData, fetchStatus]);

  const getCards = (): ICard[] => {
    if (loading) {
      return getSkeletonCards();
    }

    return latest;
  };

  const typeWriterProps = {
    options: { strings: writerStrings, autoStart: true, loop: true },
  };

  return (
    <Container>
      <TitleContainer>
        <TitleContent>
          <span>
            {slogan}
            <Typewriter {...typeWriterProps} />
          </span>
        </TitleContent>
        <p>{subtitle}</p>
      </TitleContainer>

      <CollectionStats>
        <div className="title" >
          Collection Stats
        </div>
        <div className="subtitle">
          {`Total sales in USD: ${formatInteger(parseFloat(status?.UsdRevenue || 0).toFixed(2))}`}
        </div>
      </CollectionStats>
      <ListContainer>
        <RenderStatus>
          <ItemStatus className='common'>
            <div>Common:</div>
            <div>{formatInteger(status?.CommonCount)}</div>
          </ItemStatus>
          <ItemStatus className='uncommon'>
            <div>Uncommon:</div>
            <div>{formatInteger(status?.UncommonCount)}</div>
          </ItemStatus>
          <ItemStatus className='rare'>
            <div>Rare:</div>
            <div>{formatInteger(status?.RareCount)}</div>
          </ItemStatus>
          <ItemStatus className='mythic'>
            <div>Mythic:</div>
            <div>{formatInteger(status?.MythicCount)}</div>
          </ItemStatus>
          <ItemStatus className='eldritchCount'>
            <div>Eldritch:</div>
            <div>{formatInteger(status?.EldritchCount)}</div>
          </ItemStatus>
        </RenderStatus>
        <ListTitle>
          <h3>{listTitle}</h3>
        </ListTitle>
        <ListContent>
          <RevealProvider>
            {getCards().map((card, index) => (
              <Card key={String(index)} {...card} />
            ))}
          </RevealProvider>
        </ListContent>
      </ListContainer>
    </Container>
  );
};


const mapState = (state: ApplicationState) => {
  return {
    status: state?.status?.status,
  };
};

const mapDispatch = (dispatch: any) =>
  bindActionCreators({ fetchStatus }, dispatch);

export default connect(mapState, mapDispatch)(Home);

