interface Address {
  type: 'address',
  value: string
}

interface UInt256 {
  type: 'uint256',
  value: string
}

export interface BalanceOfParams {
  account: Address;
}

export interface TransferParams {
  recipient: Address;
  amount: UInt256;
}

export interface AllowanceParams {
  spender: Address;
  owner: Address;
}

export interface PausedParams {
}

export interface IncreaseAllowanceParams {
  spender: Address;
  addedValue: UInt256;
}

export interface MarketBuyParams {
  pack: UInt256;
}

export type AvailableParams = BalanceOfParams | TransferParams | IncreaseAllowanceParams | MarketBuyParams | AllowanceParams | PausedParams

export type AvailableMethods = 'transfer' | 'balanceOf' | 'increaseAllowance' | 'marketBuy' | 'allowance' | 'paused'

const contracts = {
  transfer: "transfer(address,uint256)",
  balanceOf: "balanceOf(address)",
  allowance: "allowance(address,address)",
  increaseAllowance: "increaseAllowance(address,uint256)",
  marketBuy: "marketBuy(uint256)",
  paused: "paused()"
}

export { contracts }