import React, { createContext, useContext, useState, useCallback } from 'react';

import { inventoryApi } from '../services/api';

import { useToast } from '../hooks/Toast';
import { setTokenLocalStorage } from '../utils';

interface AuthState {
  token: string;
}

interface SignInCredentials {
  address: string;
  signature: string;
  nonce: string;
}

interface AuthContextData {
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();

  const [, setData] = useState<AuthState>(() => {
    return {} as AuthState;
  });

  const signIn = useCallback(
    async ({ address, signature, nonce }: SignInCredentials) => {
      const parsedSignature = signature.substring(2);

      try {
        const response = await inventoryApi.post('/token-auth', {
          address,
          nonce,
          signature: parsedSignature,
        });

        const { token } = response.data;
        setTokenLocalStorage(JSON.stringify({token: `Bearer ${token}`}||''))
        inventoryApi.defaults.headers.authorization = `Bearer ${token}`;



        setData({ token });
      } catch (err) {
        addToast({
          type: 'error',
          title: "Can't login to our server.",
          description: 'Something is wrong, try login again.',
        });
      }
    },
    [addToast],
  );

  const signOut = useCallback(async () => {
    await inventoryApi.post('/logout');

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider value={{ signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);

  return context;
};

export { AuthProvider, useAuth };
