import { IDropdown } from '../components/Filter/Dropdown';

export enum OrdAffinity {
  Ascendant,
  Descendant,
}

export enum OrdCreated {
  Ascendant,
  Descendant,
}

export interface IFilter {
  rarity: string;
  ancestry: string;
  personality: string;
  ordAffinity: 'asc' | 'desc' | string;
  ordCreated: 'asc' | 'desc' | string;
  [key: string]: string;
}

const defaultFilters: IFilter = {
  rarity: '',
  ancestry: '',
  personality: '',
  ordAffinity: '',
  ordCreated: '',
};

const rarityDropdown: IDropdown = {
  filters: null,
  setFilters: null,
  defaultTitle: 'rarity',
  itemList: [
    { name: 'common' },
    { name: 'uncommon' },
    { name: 'rare' },
    { name: 'mythic' },
    { name: 'eldritch' },
  ],
};

const ancestryDropdown: IDropdown = {
  filters: null,
  setFilters: null,
  defaultTitle: 'ancestry',
  arrayKey: 'ancestryType',
  itemList: [
    { name: 'lunarian' },
    { name: 'ochran' },
    { name: 'glyesian' },
    { name: 'maarish' },
    { name: 'khoroth' },
  ],
};

const personalityDropdown: IDropdown = {
  filters: null,
  setFilters: null,
  defaultTitle: 'personality',
  itemList: [
    { name: 'angry' },
    { name: 'curious' },
    { name: 'feisty' },
    { name: 'nerdy' },
    { name: 'polite' },
    { name: 'reckless' },
    { name: 'resilient' },
    { name: 'sassy' },
    { name: 'shy' },
    { name: 'stoic' },
    { name: 'klever' },
    { name: 'lavish' },
    { name: 'miner' },
    { name: 'glitched' },
    { name: 'beta' },
  ],
};

const ordAffinity: IDropdown = {
  filters: null,
  setFilters: null,
  defaultTitle: 'overall affinity',
  arrayKey: 'ordAffinity',
  itemList: [{ name: 'ascendant' }, { name: 'descendant' }],
};

const ordCreated: IDropdown = {
  filters: null,
  setFilters: null,
  defaultTitle: 'date minted',
  arrayKey: 'ordSequence',
  itemList: [{ name: 'ascendant' }, { name: 'descendant' }],
};

export {
  defaultFilters,
  rarityDropdown,
  ancestryDropdown,
  personalityDropdown,
  ordAffinity,
  ordCreated,
};
