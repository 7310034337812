import { combineReducers } from 'redux';

import inventory from './invetory';
import status from './status';

const reducers = combineReducers({
  inventory,
  status
});

export default reducers;
