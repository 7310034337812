const dark = {
  title: 'dark',

  background: '#16151A',
  white: '#fff',

  text: '#fff',

  primary: '#aa33b5',
  secondary: '#bdbdbd',

  header: {
    background: '#16151aeb',
    border: '#222227',
  },

  search: {
    background: '#222227',
  },

  footer: {
    background: '#222227',
    border: 'rgba(255, 255, 255, .1)',
  },

  switcher: {
    background: '#222227',

    icon: 'rgb(204, 204, 204)',
  },

  button: {
    background: '#aa33b5',
    hoverBackground: '#222227',
  },

  card: {
    border: '#222227',

    collection: '#0B0B1E',
  },

  selection: {
    background: '#fff',
    color: '#222227',
  },

  scrollbar: {
    background: '#222227',
    bar: '#bdbdbd',
  },
};

export default dark;
