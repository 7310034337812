import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.75rem;
  margin-bottom: 1.5rem;

  display: flex;

  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  border: 1px solid ${props => props.theme.card.border};
  border-radius: 0.5rem;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  @media (max-width: 1024px) {
    margin-top: 2rem ;

    width: 100%;
  }
  @media (max-width: 768px) {
    margin-top: 2rem;

    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;

  flex-direction: row;
  align-items: center;

  gap: 0.75rem;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
`;
