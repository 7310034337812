import { transparentize } from 'polished';
import styled from 'styled-components';

export const Container = styled.div<{ isVisible: boolean }>`
  top: 0;
  left: 0;

  height: 100vh;
  width: 100vw;

  position: fixed;
  display: flex;

  align-items: center;
  justify-content: center;

  z-index: 100;

  opacity: ${props => (props.isVisible ? 1 : 0)};
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};

  background-color: ${props => transparentize(0.5, props.theme.background)};

  transition: 0.2s ease;
`;

export const CloseContainer = styled.div`
  top: 0.875rem;
  right: 0.875rem;

  position: absolute;

  cursor: pointer;

  svg {
    font-size: 1rem;
    color: ${props => props.theme.text};

    transition: 0.2s ease;
  }
`;

export const Frame = styled.div`
  max-width: 30rem;
  padding: 1.75rem;

  background-color: ${props => props.theme.header.background};

  border: 1px solid ${props => props.theme.header.border};
  border-radius: 0.5rem;

  position: relative;
`;
