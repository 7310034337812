import { transparentize } from 'polished';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    transition: background-color .2s ease;
  }

  @media (max-width: 1080px) {
    html {
      font-size: 93.75%;
    }
  }

  @media (max-width: 720px) {
    html {
      font-size: 87.5%;
    }
  }

  body, input, textarea, button {
    background-color: ${props => props.theme.background};

    font: 500 1rem Montserrat, sans-serif;

    overflow-x: hidden !important;
  }

  main {
    margin-top: 4.375rem;
  }

  input {
    border: unset;
    
    background-color: transparent;

    &:focus {
      outline: unset;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    font-family: Montserrat, sans-serif;
  }

  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  button {
    cursor: pointer;

    background-color: transparent;

    border: unset;

    color: ${props => props.theme.white};
  }

  a {
    color: ${props => props.theme.text};
    text-decoration: none;
  }

  &::selection {
    background-color: ${props => props.theme.selection.background};
    
    color: ${props => props.theme.selection.color};
  }

  &::-webkit-scrollbar {
    width: .625rem;
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.scrollbar.background};
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.scrollbar.bar};
    border-radius: .25rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${props => transparentize(0.1, props.theme.scrollbar.bar)};
  }

  @keyframes fadein {
    from { 
      opacity: 0; 
    }

    to {
      opacity: 1;
    }
  }

`;
