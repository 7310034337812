import React, { useCallback, useEffect, useState } from 'react';

import { Container, LoaderContainer } from './styles';

import { IAsset, Status } from '../../types';
import Loader from '../Loader';
import { creatorDevikinsApi } from '../../services/api';

declare function init(
  hash: string,
  canvasId: string,
  quality: string,
  animated: boolean,
  avatar: boolean,
  context: string,
  devikin: IAsset,
  isMobile: boolean,
): Promise<any>;

declare function setHover(animation: string): any;

export interface IDevikin {
  canvasId?: string;
  quality?: string;
  animated?: boolean;
  avatar?: boolean;
  context?: string;
  data: IAsset;
  isMobile?: boolean;
  loading: boolean;
  sequenceCounter?: number;
}

const Devikin: React.FC<IDevikin> = props => {
  const [loaded, setLoaded] = useState(false);

  const { loading, data } = props;

  const sc = props.sequenceCounter || 0;

  const canvasId = props.canvasId || `devikin-${sc.toString()}`;
  const quality = props.quality || 'high';
  const animated = props.animated || false;
  const avatar = props.avatar || false;
  const context = props.context || 'webviewer';
  const isMobile = props.isMobile || false;

  const revalidateTime = 1 * 1000;

  const isUnreavealed =
    context === 'inventory' && data.Status === Status.PendingReveal;


  const loadData = useCallback(() => {

    /*if (loading) {
      return;
    }

    if (isUnreavealed) {
      setLoaded(true);
      return
    }
    setLoaded(false);
    const devikin: any = init(
      sc.toString(),
      canvasId,
      quality,
      animated,
      avatar,
      context,
      data,
      isMobile,
    );

    const interval = setInterval(async () => {
      const response = await devikin.getDevikin(props?.data?.Hash);

      console.log(response)

      if (response.assetManager.toLoad === 0) {
        setLoaded(true);
        clearInterval(interval);
      }
    }, revalidateTime);

    return () => {
      clearInterval(interval);
    };*/
  }, [loading, animated, avatar, canvasId, context, data, isMobile, isUnreavealed, quality, revalidateTime, sc, props])

  console.log(props)

  useEffect(() => {
    loadData()
  }, [loadData]);

  const handleMouseEnter = () => {
    if (loading) {
      return;
    }

    setHover('hover');
  };

  const handleMouseLeave = () => {
    if (loading) {
      return;
    }

    setHover('idle');
  };

  const containerProps = {
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
  };

  const getDevikinImage = (): string => {
    const href = 'https://img.devikins.com';
    const shard = Math.abs(Math.trunc(data.SequenceCounter / 100000));
    return `${href}/${shard}/${data.SequenceCounter}.png`;
  };

  const imageHandleError = (event: any) => {
    generateImage();
    
    event.target.onerror = null;
    event.target.src = '/assets/devikins/pendingPreview.png';
  };

  const generateImage = async () => {    
      try {
        await creatorDevikinsApi.get(
          `?sequenceCounter=${data.SequenceCounter}`,
        );
      } catch (e) {
        console.error(e);
      }    
  };

  return (
    <Container {...containerProps}>
      <img
              onError={imageHandleError}
              src={
                getDevikinImage()
              }
              alt={`Devikin #${data.SequenceCounter}`}
            />
    </Container>
  );
};

export default Devikin;
