import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { CloseContainer, Container, Frame } from './styles';

import { VscChromeClose } from 'react-icons/vsc';
import { useReveal } from '../../hooks/Reveal';
import Devikin, { IDevikin } from '../Devikin';

interface IKeyListener {
  key: string;
}

export interface IModal {
  handle(): void;
}

const Modal = forwardRef(({ children, forReveal }: any, ref) => {
  const [isVisible, setIsVisible] = useState(false);
  const { toReveal } = useReveal();

  const handle = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    document.body.style.overflow = isVisible ? 'hidden' : 'unset';
  }, [isVisible]);

  const modalProps = { handle };
  useImperativeHandle(ref, () => modalProps);

  const containerProps = { isVisible };
  const closeProps = { onClick: () => handle() };

  const RevealDevikin: React.FC = () => {
    if (toReveal?.SequenceCounter === undefined) {
      return <div />;
    }

    const devikinProps: IDevikin = {
      canvasId: `canvas-reveal`,
      data: toReveal,
      context: 'reveal',
      loading: false,
    };

    return <Devikin {...devikinProps} />;
  };

  return (
    <Container {...containerProps}>
      <Frame>
        <CloseContainer {...closeProps}>
          <VscChromeClose />
        </CloseContainer>
        {forReveal && <RevealDevikin />}
        {children}
      </Frame>
    </Container>
  );
});

export default Modal;
