import React from 'react';

import { Container ,Title  } from './styles';

import Faq from '../../components/Faq'

import helper from '../../config/helper'

const Help: React.FC = () => {
  return <Container>
    <Title>
    Frequently Asked Questions
    </Title>
    <Faq questions={helper}/>
  </Container>;
};

export default Help;
