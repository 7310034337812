import React, { useState } from 'react';

import { FaChevronDown } from 'react-icons/fa'

import { Collapse } from 'react-collapse'

import { Container ,  Title , QuestionContainer , TitleContainer } from './styles';

export interface IFaqQuestion{
  title: string;
  answer: string;
}

export interface IFaqProps {
  questions : Array<IFaqQuestion>
}

const Faq: React.FC<IFaqProps> = ({questions}) => {
  const Question: React.FC<IFaqQuestion> = ({title,answer}) => { 
    const [isOpened,setIsOpened] = useState<boolean>(false)

    return (
      <QuestionContainer  isOpened={isOpened}>
        <TitleContainer onClick={() => setIsOpened(!isOpened)} isOpened={isOpened}>
          <Title>
            {title}
          </Title>
         { <FaChevronDown color="#fff" size="16"/>}
        </TitleContainer>
        <Collapse isOpened={isOpened}>
          {answer}
        </Collapse>
      </QuestionContainer>
    )
  }

  return (
    <Container>
    {questions.map((question,index) => (
        <Question title={question.title} answer={question.answer} key={index}/>
      ))}
    </Container>
    );
};

export default Faq;
