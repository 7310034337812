import React from 'react';

import { IconType } from 'react-icons/lib';

import { useTheme } from '../../hooks/Theme';

import { Container } from './styles';

import { FiSun } from 'react-icons/fi';
import { BsMoon } from 'react-icons/bs';

const Switcher: React.FC = () => {
  const { theme, toggleTheme } = useTheme();

  const props = { onClick: toggleTheme };

  const Icon: IconType = theme.title === 'light' ? FiSun : BsMoon;

  return (
    <Container {...props}>
      <div>
        <Icon />
      </div>
    </Container>
  );
};

export default Switcher;
