import { transparentize } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 3.5rem;

  @media (max-width: 768px) {
    padding-top: 0;
  }
`;

export const CarouselContainer = styled.div`
  display: flex;

  flex-direction: row;

  align-items: center;
`;

export const CarouselDivider = styled.div`
  height: 1px;
  width: 13.5rem;

  background-color: ${props => props.theme.card.border};

  @media (max-width: 768px) {
    display: none;
  }
`;

export const HeaderContainer = styled.div`
  margin: 3.5rem 0 1.75rem 0;

  display: flex;

  flex-direction: column;

  gap: 0.75rem;

  h1 {
    font-weight: 500;
    color: ${props => props.theme.text};
  }
`;

export const SortContainer = styled.div`
  display: flex;

  flex-direction: row;

  gap: 1.25rem;
`;

export const SortItem = styled.div<{ active: boolean }>`
  padding: 0.5rem 1rem;

  display: flex;

  align-items: center;
  justify-content: center;

  background-color: ${props =>
    props.active ? props.theme.primary : props.theme.header.border};

  color: ${props =>
    props.active ? props.theme.white : transparentize(0.3, props.theme.text)};

  border-radius: 0.5rem;

  cursor: pointer;

  transition: 0.2s ease;

  &:hover {
    color: ${props => !props.active && props.theme.text};
  }
`;

export const ListContainer = styled.section`
  margin: 0 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    margin: 0 1.5rem;
  }
`;

export const ListContent = styled.div`
  display: grid;

  grid-template-columns: repeat(4, 16rem);
  grid-gap: 2rem;

  @media (max-width: 1440px) {
    grid-template-columns: repeat(3, 28vw);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 44vw);
  }

  @media (max-width: 492px) {
    grid-template-columns: auto;
  }
`;

export const ButtonContainer = styled.section`
  margin: 2.5rem 0 3.5rem 0;

  display: flex;

  align-items: center;
  justify-content: center;
`;
