import styled from 'styled-components';

import { transparentize } from 'polished';

export const Container = styled.header`
  padding: 0.8rem 4rem;

  width: 100%;

  top: 0;
  left: 0;

  z-index: 20;

  position: fixed;
  display: flex;

  flex-direction: row;
  align-items: center;

  background-color: ${props => props.theme.header.background};

  border-bottom: 1px solid ${props => props.theme.header.border};

  backdrop-filter: blur(3px);

  img {
    height: 2.25rem;
    width: auto;

    margin-right: 3.5rem;

    @media (max-width: 1024px) {
      margin-right: 2.5rem;
    }

    @media (max-width: 768px) {
      margin-right: 0;
    }
  }

  @media (max-width: 1024px) {
    padding: 0.8rem 2rem;
  }
`;

export const MobileContainer = styled.div`
  left: 0;
  top: 4.4rem;
  width: 100%;

  z-index: 2;

  padding: 1rem 2.5rem;

  position: absolute;
  display: flex;

  flex-direction: column;
  justify-content: center;

  gap: 1rem;

  visibility: hidden;
  opacity: 0;

  border-bottom: 1px solid ${props => props.theme.header.border};
  background-color: ${props => props.theme.background};
  box-shadow: 0 1px 6px
    ${props => transparentize(0.16, props.theme.header.border)};

  transition: 0.2s ease;

  a {
    font-size: 1.25rem;
  }

  svg {
    font-size: 1rem;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

export const WalletMobileContainer = styled(MobileContainer)`
  display: flex;

  flex-direction: column;

  color: ${props => props.theme.text};

  div {
    display: flex;

    flex-direction: row;
    align-items: center;

    gap: 1.5rem;

    svg {
      font-size: 1.75rem !important;
    }

    img {
      margin: 0 -3px 0 0 !important;

      height: 2rem !important;
      width: auto !important;
    }
  }
`;

export const WalletDivider = styled.div`
  width: 1px;
  height: 2rem;

  background-color: ${props => props.theme.header.border};
`;

export const Nav = styled.nav`
  display: flex;

  flex-direction: row;

  align-items: center;

  gap: 1.35rem;

  a {
    font-size: 0.875rem;
    font-weight: 400;
    color: ${props => props.theme.text};

    opacity: 0.7;

    transition: 0.2s ease;

    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Divider = styled.div`
  margin: 0 0.5rem;

  height: 2rem;
  width: 1px;

  display: block;

  background-color: ${props => props.theme.header.border};

  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const ActionContainer = styled.div`
  margin-left: auto;

  display: flex;

  align-items: center;

  gap: 1rem;

  @media (max-width: 768px) {
    div {
      font-size: 0.875rem;
    }
  }
`;

export const MobileIcon = styled.div`
  display: none;

  align-items: center;
  justify-content: center;

  svg {
    font-size: 2rem;
    color: ${props => transparentize(0.3, props.theme.text)};

    &:hover {
      color: ${props => props.theme.primary};
    }
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const LoginIcon = styled(MobileIcon)`
  display: none;

  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 425px) {
    display: flex;
  }
`;

export const LoggedContainer = styled.div`
  display: flex;

  flex-direction: column;

  gap: 0.5rem;

  div {
    display: flex;

    flex-direction: row;

    color: ${props => props.theme.text};

    svg {
      margin-right: 0.5rem;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
