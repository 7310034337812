import styled from 'styled-components';

export const Container = styled.div<{ width: number; height: number }>`
  width: ${props => props.width}rem;
  height: ${props => props.height}rem;

  position: relative;
  display: inline-block;

  div {
    margin: 0.5rem;

    width: ${props => props.width - 1}rem;
    height: ${props => props.height - 1}rem;

    position: absolute;
    display: block;

    box-sizing: border-box;

    border: 1px solid ${props => props.theme.text};
    border-radius: 50%;

    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

    border-color: ${props => props.theme.text} transparent transparent
      transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
