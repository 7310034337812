import styled from 'styled-components';

export const Container = styled.div`
  canvas {
    height: 31.25rem;
    width: 400px;

    z-index: 5;
  }
`;

export const LoaderContainer = styled.div`
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  position: absolute;
`;
