const light = {
  title: 'light',

  background: '#fff',
  white: '#fff',

  text: '#383838',

  primary: '#aa33b5',
  secondary: '#bdbdbd',

  header: {
    background: '#ffffffed',
    border: '#efefef',
  },

  search: {
    background: '#efefef',
  },

  footer: {
    background: '#efefef',
    border: 'rgba(0, 0, 0, .1)',
  },

  switcher: {
    background: '#ccc',

    icon: 'rgb(232, 179, 57)',
  },

  button: {
    background: '#aa33b5',
    hoverBackground: '#efefef',
  },

  card: {
    border: '#efefef',

    collection: '#0B0B1E',
  },

  selection: {
    background: '#222227',
    color: '#fff',
  },

  scrollbar: {
    background: '#efefef',
    bar: '#383838',
  },
};

export default light;
