import React from 'react';

import { Container } from './styles';

export interface ILoader {
  size?: number;
}

const Loader: React.FC<ILoader> = ({ size }) => {
  const defaultSize = 3;
  const loaderProps = {
    width: size || defaultSize,
    height: size || defaultSize,
  };

  return (
    <Container {...loaderProps}>
      <div />
      <div />
      <div />
      <div />
    </Container>
  );
};

export default Loader;
