import styled from 'styled-components';

import { transparentize } from 'polished';

export const Container = styled.div`
  margin: 15rem 0;
  width: 100%;

  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    display: flex;

    flex-direction: row;

    gap: 1.5rem;

    @media (max-width: 768px) {
      flex-direction: column;

      align-items: center;

      gap: 0;
    }
  }

  h1 {
    margin: 1.5rem 0;

    font-size: 2.5rem;
    color: ${props => props.theme.primary};

    @media (max-width: 768px) {
      margin: 0;
    }
  }

  span {
    max-width: 50%;

    text-align: center;
    color: ${props => transparentize(0.3, props.theme.text)};

    @media (max-width: 768px) {
      margin-top: 1.5rem;

      max-width: 80%;
    }
  }
`;
