import React from 'react';

import {
  Container,
  DescriptionContainer,
  CreditsContainer,
  NetworkContainer,
  LinksContainer,
} from './styles';

import {
  devikinsDescription,
  tabs,
  copyright,
  socialMedias,
} from '../../config/footer';

import { Logo } from '../../utils';

const Footer: React.FC = () => {
  const Tabs: React.FC = () => {
    return (
      <>
        {tabs.map((tabItem, tabIndex) => (
          <div key={String(tabIndex)}>
            <span>{tabItem.name}</span>

            {tabItem.items.map((item, index) => (
              <a key={String(index)} href={item.href}>
                {item.name}
              </a>
            ))}
          </div>
        ))}
      </>
    );
  };

  const SocialIcons: React.FC = () => {
    return (
      <div>
        {socialMedias.map((item, index) => {
          const linkProps = {
            key: String(index),
            href: item.href,
            target: '_blank',
          };

          return (
            <a {...linkProps}>
              <item.Icon />
            </a>
          );
        })}
      </div>
    );
  };

  return (
    <Container>
      <NetworkContainer>
        <DescriptionContainer>
          <Logo />
          <span>{devikinsDescription}</span>
        </DescriptionContainer>
        <LinksContainer>
          <Tabs />
        </LinksContainer>
      </NetworkContainer>
      <CreditsContainer>
        <span>{copyright}</span>
        <SocialIcons />
      </CreditsContainer>
    </Container>
  );
};

export default Footer;
