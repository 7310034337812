import React from 'react';

import { Container } from './styles';

import config from '../../config/404';
interface INotFound {
  code?: number | string;
  title?: string;
  subtitle?: string;
}

const NotFound: React.FC<INotFound> = ({ code, title, subtitle }) => {
  const {
    code: defaultCode,
    title: defaultTitle,
    subtitle: defaultSubtitle,
  } = config;

  return (
    <Container>
      <div>
        {code !== ' ' && <h1>{code || defaultCode}</h1>}
        <h1>{title || defaultTitle}</h1>
      </div>
      <span>{subtitle || defaultSubtitle}</span>
    </Container>
  );
};

export default NotFound;
