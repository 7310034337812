import React from 'react';

import { IconType } from 'react-icons/lib';

import { Container } from './styles';

import { FiCheckCircle } from 'react-icons/fi';
import { BsCircle } from 'react-icons/bs';

export const Checkbox: React.FC<any> = (props) => {
  const { onClick, selected, data, disabled, isWithdraw } = props;

  const propsButton = { onClick: () => onClick(data), disabled, };

  const Icon: IconType = selected ? FiCheckCircle : BsCircle;

  return (
    <>
      {(!data?.IsHardMinted || isWithdraw) && (
        <Container {...propsButton}>
          <div className={selected ? 'selected' : 'notselected'} >
            <Icon />
          </div>
        </Container>
      )}
      <div></div>
    </>
  );
};
