import styled from 'styled-components';

import { darken } from 'polished';

export const AddressContainer = styled.div`
  display: flex;

  flex-direction: column;
  align-items: flex-start;
`;

export const Input = styled.input`
  min-width: 300px;
  margin: 0.4rem 0.4rem;
  background-color: ${props => props.theme.secondary};
  padding: 0.4rem 0.4rem;
  border-radius: 12px;
  border: 2px solid transparent;
  background: rgba(228, 228, 228, 0.3);
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #11142d;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
`;

export const AddressHeader = styled.div`
  color: ${props => props.theme.text};

  p {
    margin-top: 0.75rem;

    font-size: 0.875rem;
    font-weight: 400;

    opacity: 0.7;
  }
`;

export const AddressDivider = styled.div`
  margin: 1rem 0;

  width: 100%;
  height: 1px;

  background-color: ${props => props.theme.card.border};
`;

export const AddressBody = styled.div`
  margin: 1rem 0;

  display: flex;

  flex-direction: column;

  gap: 0.75rem;

  color: ${props => props.theme.text};

  p {
    font-weight: 400;
    font-size: 0.875rem;

    opacity: 0.7;
  }

  span {
    font-weight: 500;
  }
`;

export const AddressInput = styled.div`
  margin: 1rem 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const AddressFooter = styled.div`
  margin-top: 0.5rem;

  width: 100%;

  display: flex;

  flex-direction: column;

  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: ${props => props.theme.text};

  span {
    font-size: 0.875rem;
    font-weight: 400;
    opacity: 0.7;
  }
`;

export const Container = styled.section`
  margin: 0 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1200px) {
    margin: 0 7.5rem;
  }

  @media (max-width: 1024px) {
    margin: 0 1.5rem;
  }

  .break {
    pointer-events: none;
  }

  .paginate-pagination {
    list-style-type: none;

    display: flex;
    justify-content: center;
    margin-top: 15px;

    li {
      cursor: pointer;
      padding: 0.5rem 0;
      margin: 0 4px;

      &:hover {
        border-radius: 0.25rem;
        background-color: ${props => darken(0.05, props.theme.primary)};
      }
      a {
        padding: 1rem;
      }
    }
  }

  .paginate-active {
    border-radius: 0.25rem;
    background-color: ${props => props.theme.primary};
  }
`;

export const Title = styled.div`
  margin: 5rem 0 2.5rem 0;

  width: 100%;

  display: flex;

  justify-content: center;

  h3 {
    color: ${props => props.theme.text};
    font-size: 1.875rem;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    h3 {
      text-align: center;
    }
  }
`;

export const Content = styled.div<{ dataSize: number }>`
  display: grid;

  grid-template-columns: repeat(4, 16rem);
  grid-gap: 2rem;

  @media (max-width: 1440px) {
    grid-template-columns: repeat(3, 28vw);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 44vw);
  }

  @media (max-width: 492px) {
    grid-template-columns: 90vw;
  }
`;

export const ActionContainer = styled.div`
  padding-bottom: 1rem;

  width: 100%;

  display: flex;

  div {
    width: fit-content;
  }

  @media (max-width: 768px) {
    div {
      width: 100%;
    }
  }
`;

export const LoaderContainer = styled.div`
  padding: 1.5rem 0;

  width: 100%;

  display: flex;

  align-items: center;
  justify-content: center;

  div {
    border-color: ${props => props.theme.primary} transparent transparent;
  }
`;

export const Div = styled.div`
  align-content: flex-start;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .klever-button {
    margin: 0px 10px;
  }
`;

export const Col = styled.div`
  padding: 0.75rem 1.25rem;
  color: white;
`;
