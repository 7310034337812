interface IAssetPart {
  id: string;
  Name: string;
  Path: string;
  Category: number;
  Tag: number;
  Ancestry: number;
  ReleaseStream: string;
  GeneId: string;
  Weight: number;
  DateCreated: string;
  BreedCount?: number;
  ParentsHashList?: string[];
}

export enum Rarity {
  Common,
  Uncommon,
  Rare,
  Mythic,
  Eldritch,
}

export enum Personality {
  Any,
  Angry,
  Curious,
  Feisty,
  Nerdy,
  Polite,
  Reckless,
  Resilient,
  Sassy,
  Shy,
  Stoic,
  Klever,
  Lavish,
  Miner,
  Glitched,
  Beta,
}

export enum Ancestry {
  Any,
  Lunarian,
  Ochran,
  Glyesian,
  Maarish,
  Khoroth,
}

export enum GeneTag {
  Dominant,
  'Co-Dominant',
  Recessive,
  Mutated,
  Unique,
  Bonded,
}

export enum GeneColors {
  '#875B27',
  '#D80000',
  '#FFE600',
  '#CD00D8',
  '#EAEAEA',
}

export enum Lifestage {
  Embryo,
  Toddler,
  Adult,
  PreEmbryo,
}
export enum Minted {
  'Soft Minted',
  'Hard Minted',
}

export enum Status {
  Enabled,
  Disabled,
  OnVoidAdventure,
  OnBreeding,
  PendingReveal,
}

export interface IAsset {
  _id: string;
  Hash: string;
  ParentHashList: string[];
  UserId: string;
  Name?: string;
  Eyes?: IAssetPart;
  Mouth?: IAssetPart;
  Hair?: IAssetPart;
  Head: IAssetPart;
  Ears?: IAssetPart;
  Horns?: IAssetPart;
  Set?: IAssetPart;
  Outfit: IAssetPart;
  AncestryType: number;
  Personality: number;
  VitAffinity: number;
  PowAffinity: number;
  ForAffinity: number;
  AgiAffinity: number;
  SanAffinity: number;
  OverallAffinity: number;
  VitBase: number;
  PowBase: number;
  ForBase: number;
  AgiBase: number;
  SanBase: number;
  IsSellable: boolean;
  Status: number;
  SequenceCounter: number;
  SystemVersion: string;
  IsHardMinted: boolean;
  DateCreated: string;
  isSelected?: boolean;
  [key: string]: any;
}
