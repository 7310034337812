import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { IAsset } from '../types';

interface IReveal {
  toReveal: IAsset;
  setToReveal: Dispatch<SetStateAction<IAsset>>;
}

const RevealContext = createContext<IReveal>({} as IReveal);

const RevealProvider: React.FC = ({ children }) => {
  const [toReveal, setToReveal] = useState<IAsset>({} as IAsset);

  const value: IReveal = { toReveal, setToReveal };

  return (
    <RevealContext.Provider value={value}>{children}</RevealContext.Provider>
  );
};

const useReveal = () => {
  const reveal = useContext(RevealContext);

  return reveal;
};

export { RevealProvider, useReveal };
