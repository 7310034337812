import styled from 'styled-components';

export const Container = styled.footer`
  margin-top: 2.5rem;
  padding: 0 15rem;

  display: flex;

  flex-direction: column;

  background-color: ${props => props.theme.footer.background};

  @media (max-width: 1024px) {
    padding: 0 1.5rem;
  }
`;

export const NetworkContainer = styled.div`
  padding: 3rem 0;

  display: flex;

  flex-direction: row;

  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;

  flex-direction: column;
  align-items: flex-start;

  gap: 0.5rem;

  img {
    margin-bottom: 0.5rem;

    height: 40px;
    width: auto;
  }

  span {
    max-width: 22.5rem;

    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: ${props => props.theme.text};

    opacity: 0.7;
  }

  @media (max-width: 768px) {
    span {
      max-width: 30rem;

      font-size: 1rem;
    }
  }
`;

export const LinksContainer = styled.div`
  width: 100%;

  display: flex;
  flex-wrap: wrap;

  justify-content: space-between;

  color: ${props => props.theme.text};

  gap: 0.5rem;

  div {
    display: flex;

    flex-direction: column;

    gap: 0.5rem;

    span {
      margin-bottom: 0.5rem;

      &:empty {
        min-height: 1rem;
      }
    }

    a {
      font-weight: 400;
      font-size: 0.875rem;
      text-decoration: none;

      opacity: 0.7;

      transition: 0.2s ease;

      &:hover {
        opacity: 1;
      }
    }

    @media (max-width: 768px) {
      flex: 45%;

      gap: 0.75rem;

      font-size: 1.15rem;

      a {
        font-size: 1rem;
      }
    }
  }

  @media (max-width: 768px) {
    justify-content: unset;

    gap: 1rem;
  }
`;

export const CreditsContainer = styled.div`
  padding: 1rem 0;

  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-top: 1px solid ${props => props.theme.footer.border};

  span {
    margin-right: auto;

    color: ${props => props.theme.text};
    font-weight: 400;
    font-size: 0.8rem;

    opacity: 0.7;
  }

  div {
    margin-left: auto;

    a {
      &:hover {
        svg {
          color: ${props => props.theme.primary};
        }
      }
    }

    svg {
      margin-left: 1.5rem;

      font-size: 1.5rem;
      color: ${props => props.theme.text};

      transition: 0.2s ease;
    }
  }
`;
