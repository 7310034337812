import React from 'react';

import { Provider } from 'react-redux';

import { BrowserRouter } from 'react-router-dom';

import AppProvider from './hooks';

import { ThemeProvider } from './hooks/Theme';

import GlobalStyle from './styles/global';

import Router from './router';

import Header from './components/Header';
import Footer from './components/Footer';

import createStore from './store';
export const store = createStore({});

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <AppProvider>
          <BrowserRouter>
            <GlobalStyle />
            <Header />

            <main>
              <Router />
            </main>

            <Footer />
          </BrowserRouter>
        </AppProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
