import React, { useCallback, useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';

import {
  Container,
  Content,
  Title,
  ActionContainer,
  LoaderContainer,
  Div,
  Col,
} from './styles';

import { ICard } from '../../components/Card';
import Modal, { IModal } from '../../components/Modal';
import Button, { IButton } from '../../components/Button';
import Dropdown, { IDropdown } from '../../components/Filter/Dropdown';
import Loader from '../../components/Loader';
import Filter from '../../components/Filter';
import { NotFound } from '..';

import {
  fetchAssetsSoftMint,
  fetchRevealed,
  revealAll,
  setFilters,
} from '../../store/reducers/invetory';

import { Ancestry, IAsset, Personality, Rarity } from '../../types';
import {
  getTitle,
} from '../../utils';
import { getSkeletonCards } from '../../mocks';
import {
  ancestryDropdown,
  ordAffinity,
  ordCreated,
  personalityDropdown,
  rarityDropdown,
} from '../../config/inventory';

import { RevealProvider } from '../../hooks/Reveal';

import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { useHistory } from 'react-router';
import { useTron } from '../../hooks/Tron';
import { ApplicationState } from '../../store/types';
import CardMint from '../../components/CardMint';
import { useToast } from '../../hooks/Toast';
import {
  ConfirmationBody,
  ConfirmationContainer,
  ConfirmationDetails,
  ConfirmationDivider,
  ConfirmationFooter,
  ConfirmationHeader,
} from '../SoftMint/styles';

const { REACT_APP_HARDMINT_CONTRACT, REACT_APP_COIN_CONTRACT } = process.env;

interface IAllFilters {
  limit: number;
  page: number;
  rarity?: string;
  ancestryType?: string;
  personality?: string;
  [key: string]: any;
}

const Inventory: React.FC<any> = ({
  fetchAssetsSoftMint,
  fetchRevealed,
  assets,
  allRevealed,
  loading,
  totalPage,
  totalItems,
  page,
  setFilters,
  filters,
}) => {
  document.title = getTitle('Inventory');

  const modalRef = useRef<IModal>(null);
  const modalConfirmatioRef = useRef<IModal>(null);
  const history = useHistory();
  const { address, transferAssetWithMessage, getMinterResources } = useTron();
  const { addToast } = useToast();

  const [devikinsSelecteds, setDevikinsSelecteds] = useState<IAsset[]>([]);
  const [processStage, setProcessStage] = useState('');
  const [data, setData] = useState<ICard[]>([]);
  const limit = 12;

  const isInitialFilters = useCallback(() => {
    return Object.values(filters).every(x => x === null || x === '');
  }, [filters]);

  const title = useCallback(() => {
    return `Inventory `;
  }, []);

  const totalCount = useCallback(() => {
    return `Total ${totalItems || 0} NFT${totalItems > 1 ? 's' : ''}`;
  }, [totalItems]);

  const getUrl = useCallback(
    (page: number) => {
      let filterState = {};
      const getIndex = (key: string, val: any) => {
        const filter = filters[key];

        let index = -1;

        const filtered = Object.values(val).filter(
          item => typeof item === 'string',
        );

        filtered.forEach((item, i) => {
          if (String(item).toLocaleLowerCase() === filter) {
            index = i;
          }
        });

        if (index !== -1) {
          filterState = { ...filterState, [key]: index };
        }
      };

      const indexesKeys = [
        { key: 'rarity', val: Rarity },
        { key: 'ancestryType', val: Ancestry },
        { key: 'personality', val: Personality },
      ];
      const ordersKeys = ['ordAffinity', 'ordCreated'];

      indexesKeys.forEach(item => getIndex(item.key, item.val));

      ordersKeys.forEach(item => {
        if (filters[item] !== '') {
          let type = filters[item].includes('asc') ? 'asc' : 'desc';

          filterState = { ...filterState, [item]: type };
        }
      });

      const allFilters: IAllFilters = { limit, page, ...filterState };

      const convertParams = Object.keys(allFilters).map(
        key => `${key}=${allFilters[key]}`,
      );

      return `/inventory?isHardMinted=false&${convertParams.join('&')}`;
    },
    [filters],
  );

  const fetchData = useCallback(
    async ({ page }: any) => {
      fetchAssetsSoftMint(getUrl(page));
    },
    [fetchAssetsSoftMint, getUrl],
  );

  const getCards = useCallback(
    (): ICard[] => (loading ? getSkeletonCards() : data),
    [data, loading],
  );

  const onPageChange = async ({ selected: page }: any) => {
    await fetchData({ page: page + 1 });
  };

  const revealButtonProps: IButton = {
    name: allRevealed ? 'All Devikins are Revealed!' : 'Reveal All',
    disabled: allRevealed,
    onClick: revealAll,
  };

  const paginateProps: ReactPaginateProps = {
    previousLabel: <FiArrowLeft />,
    nextLabel: <FiArrowRight />,
    breakLabel: '...',
    pageRangeDisplayed: 3,
    marginPagesDisplayed: 2,
    pageCount: totalPage,
    activeClassName: 'paginate-active',
    containerClassName: 'paginate-pagination',
    onPageChange,
    forcePage: page - 1,
  };

  const notFoundProps = {
    code: ' ',
    title: 'No soft mint',
    subtitle: `Yay, it looks like all your NFTs are hard mint.`,
  };

  const modalProps = { ref: modalRef, forReveal: true };

  const getDropdownProps = useCallback(
    (props: IDropdown) => {
      return {
        ...props,
        filters,
        setFilters,
      };
    },
    [filters, setFilters],
  );

  const filterProps = {
    filterHandle: async () => {
      await fetchData({ page: 1 });
    },
    btnDisabled: isInitialFilters() ? true : false,
    disabled: isInitialFilters() && data.length === 0,
  };

  useEffect(() => {
    if (!address) {
      history.push('/');
    }
    fetchRevealed();
    fetchData({ page });

    window.scrollTo(0, 0);
  }, [address, fetchRevealed, history, page, fetchData]);

  useEffect(() => {
    let newData: ICard[] = [];

    if (assets?.length) {
      newData = assets?.map((asset: IAsset) => {
        return {
          id: asset._id,
          title: `#${asset.SequenceCounter}`,
          hash: asset.Hash,
          price: 'N/A',
          devikinData: asset,
          modalRef,
        };
      });
    }
    setData(newData);
  }, [assets]);

  const modalConfirmationProps = { ref: modalConfirmatioRef, forReveal: false };

  const modalButtonProps = {
    name: 'Confirm Mint',
    style: { width: '100%' },
    onClick: () => _mintNftsMemo(),
  };

  const finishProcess = () => {
    setDevikinsSelecteds([]);
    setProcessStage('');
    modalConfirmatioRef.current?.handle();
  };

  const _mintNftsMemo = useCallback(async () => {
    setProcessStage('process');
    const { energy, bandwidth } = await getMinterResources();
    let checkMint = energy > 100_000_000 && bandwidth > 150_000;

    if (!checkMint) {
      addToast({
        type: 'error',
        title: 'Contract is out of energy, please try again later',
      });

      fetchData({ page });
      finishProcess();
      return
    }

    let data: number[] = [];

    devikinsSelecteds.forEach(devikin => {
      data.push(devikin.SequenceCounter);
    });

    try {
      const result = await transferAssetWithMessage(
        REACT_APP_COIN_CONTRACT || '',
        REACT_APP_HARDMINT_CONTRACT || '',
        '0',
        `HardMint:[${data}]`,
      );

      if (result?.result) {
        addToast({
          type: 'success',
          title: 'Hard mint successfully sent!',
          description: 'Hard mint will be completed within 12 hours!',
        });

        fetchData({ page });
        finishProcess();
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Error running hard mint, please try again!',
      });
      finishProcess();
    }
  }, [devikinsSelecteds, addToast, transferAssetWithMessage, page, fetchData, getMinterResources]);

  const mintButtonProps: IButton = {
    name: 'Hard Mint Selected',
    disabled: devikinsSelecteds?.length === 0,
    onClick: () => modalConfirmatioRef.current?.handle(),
  };

  function handleDevikins(devikin: IAsset) {
    let listDevikins = [];
    if (devikinsSelecteds.filter(d => d._id === devikin._id).length > 0) {
      listDevikins = devikinsSelecteds.filter(d => d._id !== devikin?._id);
      setDevikinsSelecteds(listDevikins);
    } else {
      if (devikinsSelecteds?.length >= 10) {
        addToast({
          type: 'info',
          title: '10 nfts already selected!',
        });
        return;
      }
      listDevikins = [...devikinsSelecteds, devikin];
      setDevikinsSelecteds(listDevikins);
    }
    return;
  }

  const isSelected = useCallback(
    (devikin: ICard) => {
      const result =
        devikinsSelecteds.findIndex(function (d: IAsset) {
          return d._id === devikin.devikinData._id;
        }) >= 0
          ? true
          : false;
      return result;
    },
    [devikinsSelecteds],
  );

  const _renderTotal = useCallback(() => {
    const result = 'Free';
    return result;
  }, []);

  const devikingsLength = useCallback(() => {
    return devikinsSelecteds?.length;
  }, [devikinsSelecteds]);

  return (
    <Container>
      <Modal {...modalConfirmationProps}>
        <ConfirmationContainer>
          <ConfirmationHeader>
            <span>{`Soft Mint NFT${devikingsLength() > 1 ? 's' : ''} selected`}</span>
            <p>You're about to hard mint:</p>
          </ConfirmationHeader>
          <ConfirmationDetails>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Number</strong>
                  </th>
                  <th>
                    <strong>Name</strong>
                  </th>
                  <th>
                    <strong>
                      {processStage === '' ? 'DVK Fees' : 'Status'}
                    </strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {devikinsSelecteds.map((devikin, index) => (
                  <tr key={String(index)}>
                    <td>{devikin.SequenceCounter}</td>
                    <td>{devikin.Name}</td>
                    {processStage === '' && <td>"Free"</td>}
                    {processStage === 'process' && <td>Process</td>}
                    {processStage === 'Error' && <td>Error</td>}
                  </tr>
                ))}
              </tbody>
            </table>
          </ConfirmationDetails>
          <ConfirmationDivider />
          <ConfirmationBody>
            <p></p>
            <span>{_renderTotal()}</span>
          </ConfirmationBody>
          <ConfirmationFooter>
            {processStage === '' ? (
              <Button {...modalButtonProps} />
            ) : (
              <Loader />
            )}
            <span>{processStage}</span>
          </ConfirmationFooter>
        </ConfirmationContainer>
      </Modal>

      <RevealProvider>
        <Modal {...modalProps} />
        <Title>
          <h3>{title()}</h3>
        </Title>
        <Div>
          <Div>
            <ActionContainer>
              <Button {...revealButtonProps} />
            </ActionContainer>
          </Div>
          <Col>
            <h3>{totalCount()}</h3>
          </Col>
          <Div>
            <ActionContainer>
              <Button {...mintButtonProps} />
            </ActionContainer>
          </Div>
        </Div>
        <Filter {...filterProps}>
          {/* <Search /> */}
          <Dropdown {...getDropdownProps(rarityDropdown)} />
          <Dropdown {...getDropdownProps(ancestryDropdown)} />
          <Dropdown {...getDropdownProps(personalityDropdown)} />
          <Dropdown {...getDropdownProps(ordAffinity)} />
          <Dropdown {...getDropdownProps(ordCreated)} />
        </Filter>
        {data?.length === 0 && !loading ? (
          <NotFound {...notFoundProps} />
        ) : (
          <>
            {loading && (
              <LoaderContainer>
                <Loader size={3.5} />
              </LoaderContainer>
            )}
            <Content dataSize={loading ? 0 : getCards().length}>
              {!loading &&
                getCards().map((card, index) => (
                  <CardMint
                    isSelected={isSelected(card)}
                    onClick={handleDevikins}
                    key={index}
                    {...card}
                  />
                ))}
            </Content>
          </>
        )}
        {totalItems > 0 && <ReactPaginate {...paginateProps} />}
      </RevealProvider>
    </Container>
  );
};

const mapState = (state: ApplicationState) => {
  return {
    assets: state?.inventory?.assetsSoftMint,
    allRevealed: state?.inventory?.allRevealed,
    loading: state?.inventory?.loading,
    totalPage: state?.inventory?.paginationSofitMint?.totalPage,
    totalItems: state?.inventory?.paginationSofitMint?.totalItems,
    page: state?.inventory?.paginationSofitMint?.page,
    filters: state?.inventory?.filters,
  };
};

const mapDispatch = (dispatch: any) =>
  bindActionCreators(
    { fetchAssetsSoftMint, fetchRevealed, revealAll, setFilters },
    dispatch,
  );

export default connect(mapState, mapDispatch)(Inventory);
