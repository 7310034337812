import styled, { css } from 'styled-components';
import { lighten } from 'polished';

interface TitleContainerProps {
  isOpened: boolean;
}

interface QuestionContainerProps {
  isOpened: boolean;
}

export const Container = styled.div`
  display: flex;
  color: ${props => props.theme.text};
  flex-direction: column;
  justify-content: center;
  max-width: 700px;
  margin: 0 auto;
`;
export const Title = styled.div`
  padding: 1rem;
`;

export const TitleContainer = styled.div<TitleContainerProps>`
  display: flex;
  color: ${props => props.theme.text};
  align-items: center;
  justify-content: space-between;

  transition: 0.2s ease;

  &:hover {
    background: ${props => lighten(0.02, props.theme.background)};
  }

  svg {
    color: ${props => props.theme.text} !important;

    transition: 0.3s ease;

    ${props =>
      props.isOpened &&
      css`
        transform: rotate(180deg);
      `};
  }
`;

export const QuestionContainer = styled.div<QuestionContainerProps>`
  margin-bottom: 1rem;
  div:last-child {
    background: ${props => props.theme.background};

    ${props =>
      props.isOpened &&
      css`
        padding: 1rem;
        background: ${props => lighten(0.05, props.theme.background)};
      `}
  }
`;
