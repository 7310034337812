import { statusDevikinsApi } from '../../services/api';
import { ReduxAction, IStatus } from '../types';

export const Types = {
    SET_STATUS: 'status/SET_STATUS',
    LOADING: 'status/LOADING',
    FAILURE: 'status/FAILURE',
};



const initialState: IStatus = {
    status: {
        CommonCount: 0,
        UncommonCount: 0,
        RareCount: 0,
        MythicCount: 0,
        EldritchCount: 0,
        UsdRevenue: 0
    }
};

export const fetchStatus = () => {
    return async (dispatch: Function) => {
        try {
            dispatch({ type: Types.LOADING });
            const { data } = await statusDevikinsApi.get('devikinAnalytics');
            dispatch({
                type: Types.SET_STATUS,
                payload: data || initialState.status,
            });

        } catch (error) {
            dispatch({ type: Types.FAILURE });
        }
    };
};

const reducer = (state = initialState, action: ReduxAction) => {
    switch (action.type) {
        case 'status/SET_STATUS':
            return {
                ...state,
                status: action.payload,
                loading: false,
                failed: false,
            };
        case 'status/LOADING':
            return { ...state, loading: true };
        case 'status/FAILURE':
            return { ...state, loading: false, failed: true };
        default:
            return state;
    }
};

export default reducer;
