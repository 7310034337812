const helper = [
  {
    title:"Do I need an account to buy?",
    answer: "No account is needed! You simply need to connect your TRX wallet ( via Menu -> Connect).",
  },
  {
    title:"Why can't I see my DVK wallet and connect?",
    answer: "DVK is a Tron token, meaning your DVK wallet address is shared with your TRX address. Instead, you must select your TRX address.",
  },
  {
    title:"Can I buy NFTs with my DVK in the Klever Exchange?",
    answer: "No, it has to be in your Klever Wallet, or any TRX address that is not from a CEX (Centralized Exchange).",
  },
  {
    title:"Help! My DVK amount isn't showing correctly?",
    answer: "Make sure your DVK is not staked. DVK that is currently staked cannot be used in to purchase NFTs.",
  },
  {
    title:"Is there a limit to how many NFTs I can buy?",
    answer: "No, you can purchase as many as you want with as many wallet as you want.",
  },
  {
    title:"Is there a limit to how many NFTs I can store in my inventory?",
    answer: "No, you can have as many NFT as you want in your inventory.",
  },
  {
    title:"Can I only buy NFTs one at a time?",
    answer: "Nope! At this moment, our shop offers packs with 1, 4, or 10 NFTs. Larger packs will be available later.",
  },{
    title:"Do I pay the gas fee (TRX/Energy and Bandwidth) per NFT?",
    answer: "Yes, each NFT is around 22 TRX (155,00 Energy) worth of gas fee. Larger packs costs less per NFT. 78 TRX for Team Pack (4 NFTs), and 175 TRX for Collector's Pack (10 NFTs)",
  },
  {
    title:"How much gas fee I need per pack?",
    answer: "Current Tron fees are 345 Bandwidth and 14.000 Energy. If you have no Bandwidth nor Energy,you need ~2 TRX to pay for the transaction. However, you need to have a balance of at least 20 TRX for your transaction be processed by the Blockchain.",
  },
  {
    title:"My purchased failed yet my DVK was removed from my wallet, what do I do?",
    answer: "In case your purchase fails after the transfer has been completed. Please reach out to team at support_at_moonlabs_dot_com with the hash transaction ID. The team will get back to you in up to 48hs.",
  },
  {
    title:"Do I have a time limit to turn my soft mint NFT into hard mint?",
    answer: "No, you can leave your NFT as soft mint as long as you want.",
  },
  {
    title:"Why do I need my NFT in hard mint state?",
    answer: "You need a hard mint NFT to transfer to another Tron wallet and to offer to the upcoming marketplace.",
  },
  {
    title:"Can I choose the rarity of my Devikin when I buy a pack?",
    answer: "No, you can't. It is randomly generated at the moment of purchase.",
  },
  {
    title:"Do I need to reveal my NFT immediately after the purchase?",
    answer: "No, you can pick to reveal it at any time.",
  },
  {
    title:"Can I send my NFT to another TRX address?",
    answer: "If your NFT is in hard mint state, yes."
  },
  {
    title:"If I hard mint my NFT, will it be vanished from my inventory?",
    answer: "No, your inventory shows both soft mint and hard mint NFT."
  },
  {
    title:"Can I sell my NFT?",
    answer: "Not yet, a Devikins Marketplace will be launched later. Only hard mint NFT will be able to be offered at the Marketplace."
  },
]

export default helper