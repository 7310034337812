import { transparentize } from 'polished';
import styled from 'styled-components';

export const Container = styled.div<{ imageName: string }>`
  margin: 0 5rem;
  padding: 1.5rem;

  width: 100%;

  display: flex;

  flex-direction: row;

  background-color: ${props => props.theme.card.border};
  background-image: ${props => `url(/assets/sliders/${props.imageName})`};
  background-position: center;

  color: ${props => props.theme.white};

  border-radius: 0.5rem;

  transition: background-image 0.2s ease;

  @media (max-width: 768px) {
    margin: 0;

    flex-direction: column;
    align-items: center;
  }
`;

export const ContainerContent = styled.div<{
  align: string;
  inFading: boolean;
}>`
  max-width: 50%;

  display: flex;

  flex-direction: column;

  align-items: flex-${props => (props.align === 'left' ? 'start' : 'end')};
  justify-content: center;

  opacity: ${props => (props.inFading ? 0 : 1)};

  transition: 0.5s ease;

  h1 {
    margin: 1.25rem 0;

    color: ${props => props.theme.white};
    font-weight: 500;
  }

  span {
    color: ${props => transparentize(0.4, props.theme.white)};
  }

  div {
    margin-top: 2.5rem;

    width: fit-content;

    background-color: ${props => props.theme.header.background};

    span {
      color: ${props => props.theme.text};
    }

    &:hover {
      background-color: ${props => props.theme.primary};

      span {
        color: ${props => props.theme.white};
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;

    align-items: center;

    text-align: center;
  }
`;

export const ControllerContainer = styled.div<{ align: string }>`
  width: 100%;

  display: flex;

  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-${props => (props.align === 'left' ? 'end' : 'start')};

  @media (max-width: 768px) {
    margin-top: 10rem;

    justify-content: center;
  }
`;

export const ControllerContent = styled.div`
  display: flex;

  flex-direction: row;
  align-items: center;

  gap: 0.5rem;
`;

export const ControllerItem = styled.div<{ active: boolean }>`
  height: 5px;
  width: ${props => (props.active ? 1.25 : 0.65)}rem;

  background-color: ${props =>
    props.active ? props.theme.white : transparentize(0.3, props.theme.white)};

  border-radius: 0.25rem;

  transition: 0.2s ease;
`;

export const ControllerArrow = styled.div`
  color: ${props => transparentize(0.3, props.theme.white)};

  cursor: pointer;

  transition: 0.2s ease;

  svg {
    font-size: 1.25rem;
  }

  &:hover {
    color: ${props => props.theme.white};
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
