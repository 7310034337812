import React, { RefObject, useEffect } from 'react';

import { Link, useHistory } from 'react-router-dom';

import { Container, Divider, Image, Title, UserAccount } from './styles';

import Skeleton from '../Skeleton';
import Button from '../Button';
import { IModal } from '../Modal';
// import { useReveal } from '../../hooks/Reveal';

// import { inventoryApi } from '../../services/api';

import { breakText } from '../../utils';
import { IAsset, Status } from '../../types';
import { useToast } from '../../hooks/Toast';
import { creatorDevikinsApi } from '../../services/api';

export interface ICard {
  modalRef?: RefObject<IModal>;
  id: string;
  title: string;
  hash: string;
  price: string;
  loading?: boolean;
  devikinData: IAsset;
}

const Card: React.FC<ICard> = ({ modalRef, title, loading, devikinData }) => {
  // const { setToReveal } = useReveal();
  const { addToast } = useToast();

  const history = useHistory();

  const isLoading = loading === undefined ? false : loading;
  const isUnrevealed = devikinData.Status === Status.PendingReveal;

  useEffect(() => {
    if (loading && loading !== undefined) {
      return;
    }
  }, [loading]);

  const OwnerAddress: React.FC = () => {
    const wordLimiter = 20;

    if (isLoading) {
      const skeletonProps = { width: '100%' };

      return <Skeleton {...skeletonProps} />;
    }

    const owner = devikinData.UserId !== undefined ? devikinData.UserId : '?';
    const linkProps = {
      onClick: () => {
        navigator.clipboard.writeText(owner);

        addToast({ type: 'info', title: 'Owner Address Copied' });
      },
    };

    return <a {...linkProps}>{breakText(owner, wordLimiter)}</a>;
  };

  const TitleContent: React.FC = () => {
    const href = `/asset/${devikinData.SequenceCounter}`;

    if (isLoading) {
      return <Skeleton />;
    }

    return (
      <Link to={href}>
        <span>{title}</span>
      </Link>
    );
  };

  const IsHardMinted: React.FC = () => {
    if (isLoading) {
      return <Skeleton />;
    }

    return <span>{devikinData?.IsHardMinted ? 'Hard Mint' : 'Soft Mint'}</span>;
  };

  const ActionButton: React.FC = () => {
    // const handleModal = () => {
    //   modalRef?.current?.handle();
    // };

    // const handleReveal = async () => {
    //   const body = {
    //     ids: [devikinData.SequenceCounter],
    //   };

    //   try {
    //     await inventoryApi.post(`reveal`, body);
    //   } catch (error) {
    //     addToast({
    //       type: 'error',
    //       title: 'Unable to reveal this devikin',
    //       description: 'Something is wrong, try reveal again.',
    //     });
    //   }
    // };

    const revealDevikin = async () => {
      if (!isUnrevealed) {
        return;
      }

      history.push(`/asset/${devikinData.SequenceCounter}`);
    };

    let buttonProps = {
      name: isUnrevealed ? 'Reveal' : 'View',
      onClick: () => revealDevikin(),
    };

    if (!isUnrevealed) {
      const href = `/asset/${devikinData.SequenceCounter}`;

      return (
        <Link to={href}>
          <Button {...buttonProps} />
        </Link>
      );
    }

    return <Button {...buttonProps} />;
  };

  const imageProps = { isUnrevealed, isLoading };
  const href = `/asset/${devikinData.SequenceCounter}`;

  const getDevikinImage = (): string => {
    const href = 'https://img.devikins.com';
    const shard = Math.abs(Math.trunc(devikinData.SequenceCounter / 100000));
    return `${href}/${shard}/${devikinData.SequenceCounter}.png`;
  };

  const generateImage = async () => {
    if (!isUnrevealed) {
      try {
        await creatorDevikinsApi.get(
          `?sequenceCounter=${devikinData.SequenceCounter}`,
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const imageHandleError = (event: any) => {
    if (history.location.pathname === '/shop/devikins/inventory') {
      generateImage();
    }
    event.target.onerror = null;
    event.target.src = '/assets/devikins/pendingPreview.png';
  };
  return (
    <Container>
      <Link to={href}>
        <Image {...imageProps}>
          {!isLoading && (
            <img
              onError={imageHandleError}
              src={
                isUnrevealed
                  ? '/assets/devikins/hidden_devikin.png'
                  : getDevikinImage()
              }
              alt={`Devikin #${devikinData.SequenceCounter}`}
            />
          )}
        </Image>
      </Link>
      <Title>
        <TitleContent />
      </Title>
      <UserAccount>
        <IsHardMinted />
        <span>Owner</span>
        <OwnerAddress />
      </UserAccount>
      <Divider />
      <ActionButton />
    </Container>
  );
};

export default Card;
