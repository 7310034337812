import React from 'react';

import { Switch, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

import { Home, Help, Inventory, Asset, NotFound, SoftMint } from '../pages';
import { Devikin } from '../pages/Shop';

const Routes: React.FC = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/help" component={Help} />
    {/* <Route exact path="/marketplace" component={Marketplace} /> */}
    <Route path="/asset/:id" component={Asset} />
    {/*<Route exact path="/shop/devikins" component={Devikin} /> */}
    <PrivateRoute exact path="/shop/devikins/inventory" component={Inventory} />
    <PrivateRoute exact path="/shop/softmint/inventory" component={SoftMint} />
    <Route path="*" component={NotFound} />
  </Switch>
);

export default Routes;
