import React from 'react';

import { Container } from './styles';

export interface IButton {
  name: string | JSX.Element;
  disabled?: boolean;
  onClick?(): void;
  isMobile?: boolean;
  style?: any;
}

const Button: React.FC<IButton> = ({
  name,
  onClick,
  disabled,
  isMobile,
  style,
}) => {
  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  const buttonProps: IButton = {
    name,
    onClick: handleClick,
    disabled,
    isMobile,
    style,
  };

  return (
    <Container {...buttonProps}>
      <span>{name}</span>
    </Container>
  );
};

export default Button;
