const links = [
  {
    name: 'Home',
    href: '/',
  },
  /*{
    name: 'Shop',
    href: '/shop/devikins',
  },*/
  {
    name: 'Help',
    href: '/help',
  },
];

const loggedLinks = [
  {
    name: 'Inventory',
    href: '/shop/devikins/inventory',
  },
];

export { links, loggedLinks };
