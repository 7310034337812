import styled from 'styled-components';

export const Container = styled.button`
  height: 2rem;
  width: 3.75rem;
  margin : 0.5rem 0 ;
  position: relative;
  display: flex;

  align-items: center;

  background-color: ${props => props.theme.switcher.background};

  border-radius: 2.1rem;

  cursor: pointer;

  transition: 0.2s ease;

  div {
    padding: 0.25rem;
    
    position: absolute;
    display: flex;

    align-items: center;
    justify-content: center;

    border-radius: 50%;

    background-color: white;

    transition: 0.6s ease;
  }
  .selected {
    right: 2rem;
    svg {
      color: #06c238
    }
  }
  .notselected {
    right: 0.5rem;
    svg {
      color: #c20606
    }
  }
  :disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;
