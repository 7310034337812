import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useTron} from '../hooks/Tron'


interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { haveAddress } = useTron();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return haveAddress() ? (
          <Component />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: location } }} />
        );
      }}
    />
  );
};

export default PrivateRoute;
