import { IconType } from 'react-icons/lib';

import { VscTwitter } from 'react-icons/vsc';
import {
  AiFillFacebook,
  AiOutlineInstagram,
  AiOutlineMail,
} from 'react-icons/ai';
import { FaTelegram } from 'react-icons/fa';

interface IItem {
  name: string;
  href: string;
}

interface ITab {
  name: string;
  items: IItem[];
}

interface ISocialMedia {
  Icon: IconType;
  href: string;
}

const devikinsDescription =
  'Digital marketplace for crypto collectibles and non-fungible tokens (NFTs). Buy, sell, and discover exclusive digital assets.';

const tabs: ITab[] = [
  {
    name: 'ECOSYSTEM',
    items: [
      {
        name: 'Wallet Android',
        href: 'https://play.google.com/store/apps/details?id=finance.klever.bitcoin.wallet',
      },
      {
        name: 'Wallet IOS',
        href: 'https://apps.apple.com/us/app/id1615064243',
      },
      {
        name: 'Exchange',
        href: 'https://klever.io/en/exchange/',
      },
      {
        name: 'Klever Finance',
        href: 'https://www.klever.finance/',
      },
    ],
  },
  {
    name: 'ABOUT',
    items: [
      {
        name: 'Careers',
        href: 'https://klever.gupy.io/',
      },
      {
        name: 'Klever News',
        href: 'https://news.klever.io/',
      },
    ],
  },
  {
    name: 'HELP CENTER',
    items: [
      {
        name: 'FAQs',
        href: 'https://klever.zendesk.com/hc/en-us',
      },
    ],
  },
  {
    name: 'CONTACT',
    items: [
      {
        name: 'feedback@klever.io',
        href: 'mailto:feedback@klever.io',
      },
    ],
  },
];

const socialMedias: ISocialMedia[] = [
  {
    Icon: AiFillFacebook,
    href: 'https://www.facebook.com/klever.io',
  },
  {
    Icon: AiOutlineInstagram,
    href: 'https://www.instagram.com/klever.io/',
  },
  {
    Icon: VscTwitter,
    href: 'https://twitter.com/klever_io',
  },
  {
    Icon: FaTelegram,
    href: 'https://t.me/Klever_io',
  },
  {
    Icon: AiOutlineMail,
    href: 'mailto:feedback@klever.io',
  },
];

const copyright = `Copyright © ${new Date().getFullYear()} Klever Marketplace.`;

export { devikinsDescription, tabs, copyright, socialMedias };
