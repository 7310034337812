import React, { useEffect } from 'react';

import { Link, useLocation } from 'react-router-dom';

import ReactGA from 'react-ga';

import {
  ActionContainer,
  Container,
  Divider,
  Nav,
  MobileIcon,
  LoginIcon,
  LoggedContainer,
  MobileContainer,
  WalletMobileContainer,
  WalletDivider,
} from './styles';

import Button, { IButton } from '../Button';
import Switcher from '../Switcher';

import { breakText, Logo } from '../../utils';
import { useTron } from '../../hooks/Tron';

import { links, loggedLinks } from '../../config/navbar';

import { HiOutlineMenuAlt3 } from 'react-icons/hi';
import { HiOutlineLink } from 'react-icons/hi';
import { BsWallet } from 'react-icons/bs';
import { FaRegAddressCard } from 'react-icons/fa';

import DvkIcon from '../../assets/devicoin.svg';

ReactGA.initialize('G-VCGB92BQ89', {
  testMode: process.env.NODE_ENV === 'test',
});

const Header: React.FC = () => {
  const { address, canConnect, toggleConnect, isLoggedIn, amount } = useTron();

  const location = useLocation();

  useEffect(() => {
    hideMenu('wallet-mobile-menu');
    ReactGA.pageview(location.pathname);
  }, [location]);

  const hideMenu = (id: string) => {
    const element = document.getElementById(id);
    if (!element) return;

    element.style.visibility = 'hidden';
    element.style.opacity = '0';
  };

  const handleMobileMenu = (id: string) => {
    const element = document.getElementById(id);
    if (!element) return;

    const type = Number(element.style.opacity) === 1 ? 1 : 0;

    element.style.visibility = type === 1 ? 'hidden' : 'visible';
    element.style.opacity = type === 1 ? '0' : '1';
  };

  const handleButton = async () => {
    if (!canConnect) {
      toggleConnect();
    }
  };

  const buttonProps: IButton = {
    name: 'Connect Wallet',
    onClick: handleButton,
    disabled: canConnect,
    isMobile: true,
  };

  const mobileButtonProps = {
    onClick: isLoggedIn
      ? () => handleMobileMenu('wallet-mobile-menu')
      : handleButton,
    disabled: canConnect,
  };

  const Links: React.FC = () => {
    let navbarLinks = [...links];
    navbarLinks.length = navbarLinks.length - 1;

    if (isLoggedIn) {
      navbarLinks = [...navbarLinks, ...loggedLinks];
    }

    navbarLinks = [...navbarLinks, links[links.length - 1]];

    return (
      <>
        {navbarLinks.map((link, index) => (
          <Link
            to={link.href}
            key={String(index)}
            onClick={() => handleMobileMenu('mobile-menu')}
          >
            {link.name}
          </Link>
        ))}
      </>
    );
  };

  const Wallet: React.FC = () => {
    if (!isLoggedIn) {
      return <Button {...buttonProps} />;
    }

    return (
      <LoggedContainer>
        <div>
          <HiOutlineLink />
          <span>{breakText(address, 10)}</span>
        </div>
      </LoggedContainer>
    );
  };

  return (
    <Container>
      <Link to="/">
        <Logo />
      </Link>
      <Nav>
        <Links />
      </Nav>
      <ActionContainer>
        <Switcher />
        <Divider />
        <Wallet />
        <LoginIcon>
          {isLoggedIn ? (
            <BsWallet {...mobileButtonProps} />
          ) : (
            <HiOutlineLink {...mobileButtonProps} />
          )}
        </LoginIcon>
        <MobileIcon onClick={() => handleMobileMenu('mobile-menu')}>
          <HiOutlineMenuAlt3 />
        </MobileIcon>
        <WalletMobileContainer id="wallet-mobile-menu">
          <div>
            <FaRegAddressCard />
            <WalletDivider />
            <p>{address}</p>
          </div>
          <div>
            <img src={DvkIcon} alt="Dvk" />
            <WalletDivider />
            <p>{amount}</p>
          </div>
        </WalletMobileContainer>
        <MobileContainer id="mobile-menu">
          <Links />
        </MobileContainer>
      </ActionContainer>
    </Container>
  );
};

export default Header;
