

import React, { useEffect, useRef, useState, useCallback } from 'react';

import { Container, Content, Icon } from './styles';

import { FaChevronDown } from 'react-icons/fa';

interface MouseDownEvent {
  target: any;
}

interface IItem {
  name: string;
}

export interface IDropdown {
  filters: any;
  setFilters: any;
  defaultTitle: string;
  arrayKey?: string;
  itemList: IItem[];
}

const Dropdown: React.FC<IDropdown> = ({
  filters,
  setFilters,
  defaultTitle,
  arrayKey,
  itemList,
}) => {
  const [selected, setSelected] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<any>(null);

  const resetName = 'All';

  useEffect(() => {
    const handleContent = (event: MouseDownEvent) => {
      if (!dropdownRef.current?.contains(event.target) && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleContent);

    return () => {
      document.removeEventListener('mousedown', handleContent);
    };
  });

  const handleSetFilter = useCallback((selected) => {
    setFilters({
      ...filters,
      [arrayKey || defaultTitle]: selected,
    });
  }, [setFilters, arrayKey, defaultTitle, filters]);

  useEffect(() => {
    handleSetFilter(selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const handleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (item: IItem) => {
    if (item.name === resetName || !item.name) {
      setSelected('');
    } else {
      setSelected(item.name);
    }

    setIsOpen(false)
  };

  const getItemList = (): IItem[] => {
    return [{ name: resetName }, ...itemList];
  };

  const getTitle = () => (selected === '' ? defaultTitle : selected);

  const _renderHandler = useCallback(() => {
    handleClick({ name: filters[arrayKey || defaultTitle] })
  }, [filters, arrayKey, defaultTitle])

  useEffect(() => {
    _renderHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const titleProps = { onClick: handleDropdown };
  const iconProps = { ...titleProps, isOpen };
  const contentProps = { isOpen, itemSize: getItemList().length };

  return (
    <Container ref={dropdownRef}>
      <span {...titleProps}>{getTitle()}</span>
      <Icon {...iconProps}>
        <FaChevronDown />
      </Icon>
      <Content {...contentProps}>
        {getItemList().map((item, index) => {
          const spanProps = {
            key: String(index),
            onClick: () => handleClick(item),
          };

          return <span {...spanProps}>{item.name}</span>;
        })}
      </Content>
    </Container>
  );
};

export default Dropdown;
