import React from 'react';

import { useTheme } from '../hooks/Theme';

import logoWhite from '../assets/logos/white.svg';
import logoBlack from '../assets/logos/black.svg';

import { AvailableParams, AvailableMethods } from '../mocks/contracts';

const defaultDecimals = 6
const TOKEN = '@klevershop/token'
const NFTS_SOFT = '@klevershop/NFTsSoft'

const Logo: React.FC = () => {
  const { theme } = useTheme();
  const logo = theme.title === 'dark' ? logoWhite : logoBlack;

  return <img src={logo} alt="Klever Logo" />;
};

const breakText = (text: string, limit: number): string => {
  return text.length > limit ? `${text.substring(0, limit)}...` : text;
};



const promiseTimeout = function (ms: number, promise: Promise<any>) {
  let timeout = new Promise((resolve, reject) => {
    let id = setTimeout(() => {
      clearTimeout(id);
      resolve('rejected');
    }, ms);
  });

  return Promise.race([promise, timeout]);
};

const getTitle = (suffix: string) => {
  const title = 'Klever Market';

  if (!suffix) {
    return title;
  }

  return `${title} – ${suffix}`;
};

interface Params {
  type: string;
  value: any;
}

type IBuilderParams = Array<Params>;

const buildParams = (params: AvailableParams): IBuilderParams => {
  const builderParams: IBuilderParams = [];

  Object.values(params).forEach(({ type, value }) => {
    builderParams.push({
      type,
      value,
    });
  });

  return builderParams;
};

interface ParseResponse {
  method: AvailableMethods;
  transaction: any;
}

const parseTronResponse = ({ method, transaction }: ParseResponse) => {

  switch (method) {
    case 'balanceOf':
    case 'allowance':
      const { constant_result } = transaction;

      return parseInt(constant_result[0], 16) / (10 ** defaultDecimals)
    case 'transfer':
      return transaction
    case 'increaseAllowance':
      return transaction.transaction;
    default:
      return;
  }
};

export {
  Logo,
  breakText,
  promiseTimeout,
  getTitle,
  buildParams,
  parseTronResponse,
};


export const setTokenLocalStorage = (items: string) => localStorage.setItem(TOKEN, items)
export const setNftsSoftLocalStorage = (items: string) => localStorage.setItem(NFTS_SOFT, items)

export const getTokenLocalStorage = () => localStorage.getItem(TOKEN)
export const getNftsSoftLocalStorage = () => localStorage.getItem(NFTS_SOFT)

export const formatInteger = (numberStr: string) => numberStr ? numberStr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0";