const slogan = 'Discover, collect, and sell extraordinary ';

const subtitle =
  'Digital marketplace for crypto collectibles and non-fungible tokens.';

const writerStrings = ['NFTs.', 'Devikins.'];

const listTitle = 'Explore exclusive digital asset';

export { slogan, subtitle, writerStrings, listTitle };
