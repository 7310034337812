import styled, { css } from 'styled-components';

const revealBackground = css`
  background-image: url('/assets/devikins/hidden_devikin.png');
  background-position: center;
  background-size: 200px auto;
  background-repeat: no-repeat;
`;

export const Container = styled.div`
  padding: 1.25rem;

  display: flex;

  flex-direction: column;

  border: 1px solid ${props => props.theme.card.border};
  border-radius: 0.75rem;

  font-weight: 400;

  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const Image = styled.div<{
  isUnrevealed?: boolean;
  isLoading?: boolean;
}>`
  width: 100%;
  min-height: 16.9rem;
  background-color: #212121;

  @media (max-width: 492px) {
    min-height: 29rem;
  }

  position: relative;

  ${props => props.isUnrevealed && !props.isLoading && revealBackground};

  border-radius: 0.75rem;

  cursor: pointer;

  canvas {
    height: 18.75rem !important;
  }

  img {
    margin: 0 auto;
    height: auto;
    width: 100%;
    border-radius: 0.5rem;
  }
`;

export const Title = styled.div`
  margin-top: 0.75rem;

  span {
    color: ${props => props.theme.text};
    font-size: 1.125rem;
    font-weight: 600;

    cursor: pointer;

    transition: 0.2s ease;

    &:hover {
      color: ${props => props.theme.primary};
    }
  }
`;

export const UserAccount = styled.div`
  margin-top: 0.75rem;

  display: flex;

  flex-direction: column;

  color: ${props => props.theme.text};

  span {
    margin-bottom: 0.25rem;

    font-size: 0.875rem;
    font-weight: 400;

    opacity: 0.7;
  }

  a {
    cursor: pointer;

    transition: 0.2s ease;

    &:hover {
      color: ${props => props.theme.primary};
    }
  }
`;

export const Divider = styled.div`
  margin: 0.75rem 0;

  width: 100%;
  height: 1px;

  background-color: ${props => props.theme.card.border};
`;

export const DetailContent = styled.div`
  margin-bottom: 0.5rem;

  display: flex;

  flex-direction: column;

  color: ${props => props.theme.text};

  p {
    margin-bottom: 0.25rem;

    font-size: 0.875rem;
    font-weight: 400;

    opacity: 0.7;
  }

  span {
    font-size: 1.15rem;
  }

  div {
    display: flex;

    flex-direction: row;

    justify-content: space-between;
  }
`;

export const PriceContainer = styled.div`
  display: flex;

  flex-direction: row;

  align-items: center;

  div {
    padding: 0.5rem 1.25rem;

    span {
      font-size: 0.875rem;
    }

    @media (max-width: 768px) {
      span {
        font-size: 1rem;
      }
    }
  }
`;
