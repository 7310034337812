import React from 'react';
import Button from '../Button';

import { ButtonContainer, Container, Content } from './styles';

interface IFilter {
  filterHandle(): void;
  btnDisabled: boolean;
  disabled: boolean;
}

const Filter: React.FC<IFilter> = ({
  children,
  filterHandle,
  btnDisabled,
  disabled,
}) => {
  const buttonProps = {
    name: 'Clear',
    onClick: filterHandle,
    disabled: btnDisabled,
  };

  return (
    <>
      {!disabled && (
        <Container>
          <Content>{children}</Content>
          <ButtonContainer>
            <Button {...buttonProps} />
          </ButtonContainer>
        </Container>
      )}
    </>
  );
};

export default Filter;
