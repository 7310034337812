import styled from 'styled-components';

export const Container = styled.div`
  height: 2rem;
  width: 3.75rem;

  position: relative;
  display: flex;

  align-items: center;

  background-color: ${props => props.theme.switcher.background};

  border-radius: 2.1rem;

  cursor: pointer;

  transition: 0.2s ease;

  div {
    padding: 0.25rem;

    right: ${props => (props.theme.title === 'dark' ? 0.5 : 2)}rem;

    position: absolute;
    display: flex;

    align-items: center;
    justify-content: center;

    border-radius: 50%;

    background-color: white;

    transition: 0.6s ease;

    svg {
      color: ${props => props.theme.switcher.icon};
    }
  }
`;
