import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { breakText, getTokenLocalStorage } from '../../utils';

import { useHistory, useParams } from 'react-router';

import { useTron } from '../../hooks/Tron';

import {
  AttributesContainer,
  AssetContainer,
  AssetInfoContainer,
  AssetInfoContent,
  AssetPaginationContainer,
  Container,
  ViewerContainer,
  AttributesTitle,
  AttributesContent,
  AttributesDetails,
  AttributesDetailsContent,
  Input,
  Div,
  Button as ButtonChange,
  Paragraph,
  ParentsLink,
} from './styles';

import NotFound from '../NotFound';

import {
  Ancestry,
  Rarity,
  GeneColors,
  GeneTag,
  IAsset,
  Lifestage,
  Personality,
} from '../../types';

import {
  fetchPrevAssets,
  fetchNextAssets,
} from '../../store/reducers/invetory';

import Skeleton from '../../components/Skeleton';
import Devikin from '../../components/Devikin';
import Button, { IButton } from '../../components/Button';

import { format } from 'date-fns';

import AgiBase from '../../assets/devikins/attributes/basic/agi.svg';
import AgiCommon from '../../assets/devikins/attributes/agi/01.svg';
import AgiUncommon from '../../assets/devikins/attributes/agi/02.svg';
import AgiRare from '../../assets/devikins/attributes/agi/03.svg';
import AgiMythic from '../../assets/devikins/attributes/agi/04.svg';
import AgiEldritch from '../../assets/devikins/attributes/agi/05.svg';

import ForBase from '../../assets/devikins/attributes/basic/for.svg';
import ForCommon from '../../assets/devikins/attributes/for/01.svg';
import ForUncommon from '../../assets/devikins/attributes/for/02.svg';
import ForRare from '../../assets/devikins/attributes/for/03.svg';
import ForMythic from '../../assets/devikins/attributes/for/04.svg';
import ForEldritch from '../../assets/devikins/attributes/for/05.svg';

import PowBase from '../../assets/devikins/attributes/basic/pow.svg';
import PowCommon from '../../assets/devikins/attributes/pow/01.svg';
import PowUncommon from '../../assets/devikins/attributes/pow/02.svg';
import PowRare from '../../assets/devikins/attributes/pow/03.svg';
import PowMythic from '../../assets/devikins/attributes/pow/04.svg';
import PowEldritch from '../../assets/devikins/attributes/pow/05.svg';

import SanBase from '../../assets/devikins/attributes/basic/san.svg';
import SanCommon from '../../assets/devikins/attributes/san/01.svg';
import SanUncommon from '../../assets/devikins/attributes/san/02.svg';
import SanRare from '../../assets/devikins/attributes/san/03.svg';
import SanMythic from '../../assets/devikins/attributes/san/04.svg';
import SanEldritch from '../../assets/devikins/attributes/san/05.svg';

import VitBase from '../../assets/devikins/attributes/basic/vit.svg';
import VitCommon from '../../assets/devikins/attributes/vit/01.svg';
import VitUncommon from '../../assets/devikins/attributes/vit/02.svg';
import VitRare from '../../assets/devikins/attributes/vit/03.svg';
import VitMythic from '../../assets/devikins/attributes/vit/04.svg';
import VitEldritch from '../../assets/devikins/attributes/vit/05.svg';

import MouthDominant from '../../assets/devikins/attributes/mouth/Dominant.svg';
import MouthCoDominant from '../../assets/devikins/attributes/mouth/Co-Dominant.svg';
import MouthRecessive from '../../assets/devikins/attributes/mouth/Recessive.svg';
import MouthMutated from '../../assets/devikins/attributes/mouth/Mutated.svg';
// import MouthUnique from '../../assets/devikins/attributes/mouth/Unique.svg';
import MouthBonded from '../../assets/devikins/attributes/mouth/Bonded.svg';

import EyesDominant from '../../assets/devikins/attributes/eyes/Dominant.svg';
import EyesCoDominant from '../../assets/devikins/attributes/eyes/Co-Dominant.svg';
import EyesRecessive from '../../assets/devikins/attributes/eyes/Recessive.svg';
import EyesMutated from '../../assets/devikins/attributes/eyes/Mutated.svg';
import EyesUnique from '../../assets/devikins/attributes/eyes/Unique.svg';
import EyesBonded from '../../assets/devikins/attributes/eyes/Bonded.svg';

import EarsDominant from '../../assets/devikins/attributes/ears/Dominant.svg';
import EarsCoDominant from '../../assets/devikins/attributes/ears/Co-Dominant.svg';
import EarsRecessive from '../../assets/devikins/attributes/ears/Recessive.svg';
import EarsMutated from '../../assets/devikins/attributes/ears/Mutated.svg';
import EarsUnique from '../../assets/devikins/attributes/ears/Unique.svg';
import EarsBonded from '../../assets/devikins/attributes/ears/Bonded.svg';

import HairDominant from '../../assets/devikins/attributes/hair/Dominant.svg';
import HairCoDominant from '../../assets/devikins/attributes/hair/Co-Dominant.svg';
import HairRecessive from '../../assets/devikins/attributes/hair/Recessive.svg';
import HairMutated from '../../assets/devikins/attributes/hair/Mutated.svg';
import HairUnique from '../../assets/devikins/attributes/hair/Unique.svg';
import HairBonded from '../../assets/devikins/attributes/hair/Bonded.svg';

import HornsDominant from '../../assets/devikins/attributes/horns/Dominant.svg';
import HornsCoDominant from '../../assets/devikins/attributes/horns/Co-Dominant.svg';
import HornsRecessive from '../../assets/devikins/attributes/horns/Recessive.svg';
import HornsMutated from '../../assets/devikins/attributes/horns/Mutated.svg';
import HornsUnique from '../../assets/devikins/attributes/horns/Unique.svg';
import HornsBonded from '../../assets/devikins/attributes/horns/Bonded.svg';

import { inventoryApi } from '../../services/api';
import { getTitle } from '../../utils';
import { ApplicationState } from '../../store/types';
import { useToast } from '../../hooks/Toast';
import { get } from 'lodash';
import { bindActionCreators } from 'redux';

const geneIcons = {
  mouth: [
    MouthDominant,
    MouthCoDominant,
    MouthRecessive,
    MouthMutated,
    null,
    MouthBonded,
  ],
  eyes: [
    EyesDominant,
    EyesCoDominant,
    EyesRecessive,
    EyesMutated,
    EyesUnique,
    EyesBonded,
  ],
  ears: [
    EarsDominant,
    EarsCoDominant,
    EarsRecessive,
    EarsMutated,
    EarsUnique,
    EarsBonded,
  ],
  hair: [
    HairDominant,
    HairCoDominant,
    HairRecessive,
    HairMutated,
    HairUnique,
    HairBonded,
  ],
  horns: [
    HornsDominant,
    HornsCoDominant,
    HornsRecessive,
    HornsMutated,
    HornsUnique,
    HornsBonded,
  ],
};

const affIcons = {
  agi: [AgiCommon, AgiUncommon, AgiRare, AgiMythic, AgiEldritch],
  for: [ForCommon, ForUncommon, ForRare, ForMythic, ForEldritch],
  pow: [PowCommon, PowUncommon, PowRare, PowMythic, PowEldritch],
  san: [SanCommon, SanUncommon, SanRare, SanMythic, SanEldritch],
  vit: [VitCommon, VitUncommon, VitRare, VitMythic, VitEldritch],
};

interface IAssetParams {
  id?: string;
}
interface IAssetName {
  name?: string;
}

interface IRow {
  title: string;
  keys: {
    name: string;
    key: string;
    enum?: any;
    [key: string]: any;
  }[];
}

interface IAssetInfo {
  Creator: string;
  Collection: string;
  Owner: string;
  [key: string]: string;
}

interface IAllFilters {
  limit: number;
  page: number;
  rarity?: string;
  ancestryType?: string;
  personality?: string;
  [key: string]: any;
}

const Asset: React.FC<any> = ({
  assets,
  filters,
  totalPage,
  page,
  fetchPrevAssets,
  fetchNextAssets,
}) => {
  document.title = getTitle('NFT Detail');

  const history = useHistory();

  const limit = 12;

  const { id } = useParams<IAssetParams>();

  const { address, isLoggedIn } = useTron();

  const [notFound, setNotFound] = useState(false);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IAsset>({} as IAsset);
  const [nameDevikin, setNameDevikin] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [pagination, setPagination] = useState({ prev: null, next: null });
  const [isUserNft, setIsUserNft] = useState(false);

  useEffect(() => {
    if (address === data?.UserId) {
      setIsUserNft(true);
    }
  }, [address, data]);

  const { addToast } = useToast();

  const getUrl = useCallback(
    (page: number) => {
      let filterState = {};
      const getIndex = (key: string, val: any) => {
        const filter = filters[key];

        let index = -1;

        const filtered = Object.values(val).filter(
          item => typeof item === 'string',
        );

        filtered.forEach((item, i) => {
          if (String(item).toLocaleLowerCase() === filter) {
            index = i;
          }
        });

        if (index !== -1) {
          filterState = { ...filterState, [key]: index };
        }
      };

      const indexesKeys = [
        { key: 'rarity', val: Rarity },
        { key: 'ancestryType', val: Ancestry },
        { key: 'personality', val: Personality },
      ];
      const ordersKeys = ['ordAffinity', 'ordCreated'];

      indexesKeys.forEach(item => getIndex(item.key, item.val));

      ordersKeys.forEach(item => {
        if (filters[item] !== '') {
          let type = filters[item].includes('asc') ? 'asc' : 'desc';

          filterState = { ...filterState, [item]: type };
        }
      });

      const allFilters: IAllFilters = { limit, page, ...filterState };

      const convertParams = Object.keys(allFilters).map(
        key => `${key}=${allFilters[key]}`,
      );

      return `/inventory?${convertParams.join('&')}`;
    },
    [filters],
  );

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await inventoryApi.get(`/asset/${id}`);

      if (response.data.UserId === address && response.data.Status === 4) {
        inventoryApi.post('reveal', {
          ids: [response.data.SequenceCounter],
        });
      }

      setData(response.data);
      setLoading(false);
    } catch (error) {
      setNotFound(true);
    }
  }, [address, id]);

  useEffect(() => {
    if (!id) {
      return;
    }

    fetchData();
    window.scrollTo(0, 0);
  }, [fetchData, id]);

  const _ChangeNameDevikin = useCallback(async () => {
    if (!isUserNft) return;

    if (nameDevikin?.length < 3) {
      return addToast({
        type: 'error',
        title: 'The name must have at least 3 characters!',
      });
    }

    if (nameDevikin?.length > 12) {
      return addToast({
        type: 'error',
        title: 'The name must have a maximum of 12 characters!',
      });
    }

    const { token } = JSON.parse(getTokenLocalStorage() || '');
    inventoryApi.defaults.headers.authorization = token;
    try {
      const response = await inventoryApi.post(
        `/rename/${data?.SequenceCounter}`,
        { newName: nameDevikin },
      );
      if (response.status === 200) {
        fetchData();
        setNameDevikin('');
        setIsVisible(false);
        addToast({
          type: 'success',
          title: 'The name was changed successfully!',
        });
      }
    } catch (error) {
      const errorResponse = get(
        error,
        'response.data.error',
        'Error changing name',
      );
      addToast({
        type: 'error',
        title: errorResponse,
      });
    }
  }, [addToast, fetchData, nameDevikin, data, isUserNft]);

  useEffect(() => {
    if (!(!pagination.next && !pagination.prev)) {
      if (!pagination.next && page < totalPage) {
        fetchNextAssets(getUrl(page + 1));
      }
      if (!pagination.prev && page > 1) {
        fetchPrevAssets(getUrl(page - 1));
      }
    }
  }, [fetchNextAssets, fetchPrevAssets, page, totalPage, getUrl, pagination]);

  const findPrevNext = useCallback(
    (id: string) => {
      const i = assets?.findIndex((asset: any) => asset._id === id);
      if (i >= 0) {
        const prev = i > 0 ? assets[i - 1]?.SequenceCounter : null;
        const next =
          i < assets?.length - 1 ? assets[i + 1]?.SequenceCounter : null;

        setPagination({ prev, next });
      }
    },
    [assets],
  );

  useEffect(() => {
    if (data._id) {
      findPrevNext(data?._id);
    }
  }, [findPrevNext, data, assets]);

  const nextAsset = useCallback(() => {
    if (pagination.next) {
      history.push(`/asset/${pagination.next}`);
    }
  }, [history, pagination]);

  const prevAsset = useCallback(() => {
    if (pagination.prev) {
      history.push(`/asset/${pagination.prev}`);
    }
  }, [pagination, history]);

  const notFoundProps = {
    code: 404,
    title: 'Asset not found',
    subtitle: `Oops, it looks like this asset doesn't exist, please review your link or go back to the main page`,
  };
  if (!id || notFound) {
    return <NotFound {...notFoundProps} />;
  }

  const devikinProps = {
    hash: id || '',
    animated: true,
    data,
    loading,
    index: 0,
    sequenceCounter: data.SequenceCounter || 0,
  };

  const AssetInfos: React.FC = () => {
    const wordLimiter = 5;
    const owner = data.UserId !== undefined ? data.UserId : '?';

    const infos: IAssetInfo = {
      Creator: '@Devikins',
      Collection: 'Devikins',
      Owner: breakText(owner, wordLimiter),
    };

    return (
      <AssetInfoContainer>
        {Object.keys(infos).map((key, index) => (
          <AssetInfoContent key={String(index)}>
            <span>{key}</span>
            {loading ? <Skeleton /> : <p>{infos[key]}</p>}
          </AssetInfoContent>
        ))}
      </AssetInfoContainer>
    );
  };

  const _setValue = (e: string) => {
    return setNameDevikin(e);
  };

  const _editDevikinName = (isVisible: boolean) => {
    if (isVisible) {
      _ChangeNameDevikin();
    } else {
      setIsVisible(!isVisible);
    }
  };

  const AssetPagination: React.FC = () => {
    const buttonPrevProps: IButton = {
      name: '← Prev',
      onClick: prevAsset,
      disabled: !pagination.prev,
    };
    const buttonNextProps: IButton = {
      name: 'Next →',
      onClick: nextAsset,
      disabled: !pagination.next,
    };

    return (
      <>
        {isLoggedIn && (pagination.next || pagination.prev) && (
          <AssetPaginationContainer>
            <Button {...buttonPrevProps} />
            <Button {...buttonNextProps} />
          </AssetPaginationContainer>
        )}
      </>
    );
  };

  const disabledInputAndCancel =
    isLoggedIn && isVisible && !data?.Name && isUserNft;
  const disabledSaveButton = isLoggedIn && !data?.Name && isUserNft;

  const Title: React.FC = () => {
    let name =
      (data.Name !== undefined ? data.Name + ' ' : '') +
      ` #${data.SequenceCounter}`;

    const date = `Minted at ${format(
      new Date(data.DateCreated || new Date()),
      'MM-dd-yyyy',
    )}`;

    const IsHardMinted = data?.IsHardMinted ? 'Hard Mint' : 'Soft Mint';

    if (loading) {
      return <Skeleton />;
    }

    return (
      <>
        <h1>{name}</h1>
        <span>{date}</span>
        <br></br>
        <div className="minted">{IsHardMinted}</div>
        <Div>
          {disabledSaveButton && (
            <ButtonChange
              className="minted"
              onClick={() => _editDevikinName(isVisible)}
            >
              {isVisible ? 'Save' : 'Set Nickname'}{' '}
            </ButtonChange>
          )}
          {disabledInputAndCancel && (
            <ButtonChange
              className="minted"
              onClick={() => setIsVisible(false)}
            >
              {'Cancel'}{' '}
            </ButtonChange>
          )}
        </Div>
        {disabledInputAndCancel && (
          <Input
            type="text"
            placeholder="Devikin Name"
            value={nameDevikin}
            autoFocus
            onChange={e => _setValue(e.target.value)}
          />
        )}
      </>
    );
  };

  const Details: React.FC = () => {
    const rows: IRow[] = [
      {
        title: 'Information',
        keys: [
          { name: 'Personality', key: 'Personality', enum: Personality },
          { name: 'Ancestry', key: 'AncestryType', enum: Ancestry },
          { name: 'Life Stage', key: 'Stage', enum: Lifestage },
        ],
      },
      {
        title: 'Affinities',
        keys: [
          { name: 'Vitality', key: 'VitAffinity', icon: affIcons.vit },
          { name: 'Power', key: 'PowAffinity', icon: affIcons.pow },
          { name: 'Fortitude', key: 'ForAffinity', icon: affIcons.for },
          { name: 'Agility', key: 'AgiAffinity', icon: affIcons.agi },
          { name: 'Sanity', key: 'SanAffinity', icon: affIcons.san },
        ],
      },
      {
        title: 'Genes',
        keys: [
          {
            name: 'Mouth',
            key: 'Mouth',
            enum: GeneTag,
            color: true,
            icon: geneIcons.mouth,
          },
          {
            name: 'Eyes',
            key: 'Eyes',
            enum: GeneTag,
            color: true,
            icon: geneIcons.eyes,
          },
          {
            name: 'Ears',
            key: 'Ears',
            enum: GeneTag,
            color: true,
            icon: geneIcons.ears,
          },
          {
            name: 'Hair',
            key: 'Hair',
            enum: GeneTag,
            color: true,
            icon: geneIcons.hair,
          },
          {
            name: 'Horns',
            key: 'Horns',
            enum: GeneTag,
            color: true,
            icon: geneIcons.horns,
          },
        ],
      },
      {
        title: 'Basic Attributes',
        keys: [
          { name: 'Vitality', key: 'VitBase', icon: VitBase },
          { name: 'Power', key: 'PowBase', icon: PowBase },
          { name: 'Fortitude', key: 'ForBase', icon: ForBase },
          { name: 'Agility', key: 'AgiBase', icon: AgiBase },
          { name: 'Sanity', key: 'SanBase', icon: SanBase },
        ],
      },
      {
        title: 'Procreation',
        keys: [
          { name: 'Parents', key: 'ParentHashList' },
          { name: 'Left', key: 'BreedCount' },
        ],
      },
    ];

    return (
      <>
        {rows.map((row, index) => (
          <AttributesContent key={String(index)}>
            <h3>{row.title}</h3>

            <AttributesDetails procreation={row.title === 'Procreation'}>
              {row.keys.map((key, index2) => {
                let assetKey = key.key;
                if (row.title === 'Genes' && data.Set) {
                  assetKey = 'Set';
                }

                let info = data[assetKey];
                if (typeof info === 'object') {
                  info = info['Tag'];
                }

                let color = 'transparent';
                if (key.color) {
                  color = GeneColors[Number(info)];
                }

                if (assetKey === 'BreedCount') {
                  info = 10 - data.BreedCount || "--";
                }

                if (
                  assetKey === 'ParentHashList' &&
                  data?.ParentHashList?.length === 2
                ) {
                  info = data?.ParentHashList?.map((item, index) => {
                    const href = 'https://img.devikins.com';
                    const shard = Math.abs(Math.trunc(Number(item) / 100000));
                    const imageURL = `${href}/${shard}/${item}.png`;

                    return (
                      <ParentsLink
                        to={`/asset/${item}`}
                        key={index}
                        target="_blank"
                      >
                        <img
                          src={
                            imageURL || '/assets/devikins/pendingPreview.png'
                          }
                          alt={`Parent ${item}`}
                        />
                        {item || '--'}
                      </ParentsLink>
                    );
                  });
                }

                const Icon: React.FC = () => {
                  if (key.icon) {
                    let iconRef = key.icon;

                    if (Array.isArray(key.icon)) {
                      iconRef = key.icon[Number(info)];

                      if (row.title === 'Affinities') {
                        let index = 0;
                        let assetValue = Number(info);

                        if (assetValue <= 3) {
                          index = 0;
                        } else if (assetValue < 6) {
                          index = 1;
                        } else if (assetValue < 8) {
                          index = 2;
                        } else if (assetValue < 10) {
                          index = 3;
                        } else if (assetValue >= 10) {
                          index = 4;
                        }

                        iconRef = key.icon[index];
                      }
                    }

                    return <img src={iconRef} alt="icon" />;
                  }

                  return <></>;
                };

                return (
                  <div key={String(index2)}>
                    {!loading && <Icon />}
                    <AttributesDetailsContent color={color}>
                      <span>{key.name}</span>
                      {loading ? (
                        <Skeleton />
                      ) : (
                        <Paragraph>
                          {key.enum ? key.enum[Number(info)] : info || '--'}
                        </Paragraph>
                      )}
                    </AttributesDetailsContent>
                  </div>
                );
              })}
            </AttributesDetails>
          </AttributesContent>
        ))}
      </>
    );
  };

  return (
    <Container>
      <ViewerContainer>
        <AssetContainer>
          <Devikin {...devikinProps} />
          <AssetInfos />
          <AssetPagination />
        </AssetContainer>
        <AttributesContainer>
          <AttributesTitle>
            <Title />
          </AttributesTitle>
          <Details />
        </AttributesContainer>
      </ViewerContainer>
    </Container>
  );
};

const mapState = (state: ApplicationState) => {
  return {
    assets: state?.inventory?.assets,
    totalPage: state?.inventory?.pagination?.totalPage,
    page: state?.inventory?.pagination?.page,
    filters: state?.inventory?.filters,
  };
};
const mapDispatch = (dispatch: any) =>
  bindActionCreators({ fetchPrevAssets, fetchNextAssets }, dispatch);

export default connect(mapState, mapDispatch)(Asset);
