import React from 'react';

import { ThemeProvider } from 'styled-components';

import { TronProvider } from './Tron';
import { ToastProvider } from './Toast';
import { AuthProvider } from './Auth';
import { useTheme } from './Theme';

const AppProvider: React.FC = ({ children }) => {
  const { theme } = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <ToastProvider>
          <AuthProvider>
            <TronProvider>{children}</TronProvider>
          </AuthProvider>
      </ToastProvider>
    </ThemeProvider>
  );
};

export default AppProvider;
