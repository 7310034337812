import React, { createContext, useContext, useState } from 'react';

import { dark, light } from '../styles/themes';
import { Theme } from '../styles/styles';

interface ITheme {
  theme: Theme;
  toggleTheme(): void;
}

const defaultTheme: ITheme = {
  theme: dark,
  toggleTheme: () => {},
};

const ThemeContext = createContext<ITheme>(defaultTheme);

const ThemeProvider: React.FC = ({ children }) => {
  const key = '@klevershop/theme';

  const [theme, setTheme] = useState<Theme>(() => {
    const storageValue = localStorage.getItem(key);

    if (storageValue) {
      return JSON.parse(storageValue);
    }

    return defaultTheme.theme;
  });

  const toggleTheme = () => {
    const newTheme = theme.title === 'light' ? dark : light;

    setTheme(newTheme);
    localStorage.setItem(key, JSON.stringify(newTheme));
  };

  const providerProps = { value: { theme, toggleTheme } };

  return (
    <ThemeContext.Provider {...providerProps}>{children}</ThemeContext.Provider>
  );
};

const useTheme = () => {
  const context = useContext(ThemeContext);

  return context;
};

export { ThemeProvider, useTheme };
