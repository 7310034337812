import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';

import crypto from 'crypto';
import { v4 as uuid } from 'uuid';

import { useAuth } from './Auth';
import { useToast } from './Toast';
import {
  promiseTimeout,
  buildParams,
  parseTronResponse,
} from '../utils';

import { ErrUserRefusedToSign, ErrTimeout } from '../mocks/errors';

import {
  contracts,
  AvailableParams,
  AvailableMethods,
  BalanceOfParams,
  AllowanceParams,
  TransferParams,
} from '../mocks/contracts';

const {
  REACT_APP_COIN_CONTRACT,
  REACT_APP_MARKET_CONTRACT,
  REACT_APP_HARDMINT_CONTRACT,
  REACT_APP_NFT_CONTRACT,
} = process.env;

declare global {
  interface Window {
    tronWeb: any;
  }
}

interface SignProps {
  data: string | Object;
}

interface AllowanceProps {
  owner: string;
}

interface TriggerContractProps {
  contractAddress?: string;
  triggerMethod: AvailableMethods;
  params?: AvailableParams;
  address?: string;
  amount?: string;
}

interface INewAddress {
  base58: string;
}

interface ITron {
  address: string;
  amount: number;
  isInstalled: boolean;
  isLoggedIn: boolean;
  canConnect: boolean;
  toggleConnect(): void;
  changeAddress(): void;
  haveAddress(): boolean;
  isMarketPaused(): Promise<any>;
  getTrxAmount(): Promise<any>;
  getTx(txID: string): Promise<any>;
  getResources(): Promise<any>;
  getMinterResources(): Promise<any>;
  getNFTContractResources(): Promise<any>;
  getDvkAmount(address: string): Promise<any>;
  updateAmount(address: string): void;
  sign(data: SignProps): Promise<string>;
  marketBuy(amount: string): Promise<any>;
  allowance(data: AllowanceProps): Promise<any>;
  increaseAllowance(amount: string): Promise<any>;
  transferWithMessage(
    address: string,
    amount: string,
    message: string,
  ): Promise<any>;
  transferFrom(toAddress: string, tokensIDs: Array<number>): Promise<any>;
  transferAssetWithMessage(
    contractAddress: string,
    address: string,
    amount: string,
    message: string,
  ): Promise<any>;
}

const defaultData: ITron = {
  address: '',
  isInstalled: false,
  isLoggedIn: false,
  canConnect: false,
  amount: 0,
  toggleConnect: () => { },
  changeAddress: () => { },
  isMarketPaused: async () => {
    new Promise(resolve => resolve(false));
  },
  getTrxAmount: async () => {
    new Promise(resolve => resolve({}));
  },
  getTx: async (txID: string) => {
    new Promise(resolve => resolve(txID));
  },
  getResources: async () => {
    new Promise(resolve => resolve({}));
  },
  getMinterResources: async () => {
    new Promise(resolve => resolve({}));
  },
  getNFTContractResources: async () => {
    new Promise(resolve => resolve({}));
  },
  getDvkAmount: async () => {
    new Promise(resolve => resolve({}));
  },
  updateAmount: (_: string) => { },
  increaseAllowance: async (amount: string) => {
    new Promise(resolve => resolve(amount));
  },
  allowance: async (data: AllowanceProps) => {
    new Promise(resolve => resolve(data));
  },
  marketBuy: async (amount: string) => {
    new Promise(resolve => resolve(amount));
  },
  haveAddress: () => true,
  sign: ({ data }: SignProps) =>
    new Promise(resolve => {
      resolve(data as string);
    }),
  transferWithMessage: (_: string, __: string, message: string) =>
    new Promise(resolve => {
      resolve(message as string);
    }),
  transferFrom: (_: string, tokensIDs: Array<number>) =>
    new Promise(resolve => {
      resolve(tokensIDs as Array<number>);
    }),
  transferAssetWithMessage: (
    _: string,
    __: string,
    ___: string,
    message: string,
  ) =>
    new Promise(resolve => {
      resolve(message as string);
    }),
};

const TronContext = createContext<ITron>(defaultData);

const TronProvider: React.FC = ({ children }) => {
  const DVK = {
    coin: REACT_APP_COIN_CONTRACT,
    market: REACT_APP_MARKET_CONTRACT,
  };
  const defaultIntervalTime = 500;

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const [isInstalled, setIsInstalled] = useState<boolean>(false);
  const [isLoggedIn, setisLoggedIn] = useState<boolean>(false);
  const [canConnect, setCanConnect] = useState<boolean>(false);

  const [address, setAddress] = useState<string>('');
  const [amount, setAmount] = useState<number>(0);

  useEffect(() => {
    if (!canConnect) {
      return;
    }

    const tronWatcher = setInterval(async () => {
      if (window?.tronWeb && window?.tronWeb?.ready) {
        setIsInstalled(true);
        clearInterval(tronWatcher);
      }
    }, defaultIntervalTime);

    const addressWatcher = setInterval(async () => {
      if (window?.tronWeb?.defaultAddress?.base58) {
        setAddress(window?.tronWeb?.defaultAddress?.base58);

        clearInterval(addressWatcher);
      }
    }, defaultIntervalTime);

    return () => {
      clearInterval(tronWatcher);
      clearInterval(addressWatcher);
    };
  }, [canConnect, isInstalled]);

  useEffect(() => {
    if (!canConnect) {
      return;
    }

    if (!window?.tronWeb?.ready) {
      addToast({
        type: 'info',
        title: 'Please connect your wallet first!',
      });
      setCanConnect(false);
    }
  }, [canConnect, addToast]);


  const generateNonce = () => {
    if (!window?.tronWeb) {
      return
    }

    const randomNonce = crypto.createHash('sha256').update(uuid()).digest('hex')

    if (navigator.userAgent.includes('TronWallet')) {
      return randomNonce
    } else {
      return window?.tronWeb?.toHex(`Klever NFT ${randomNonce}`).substring(2)
    }

  }


  const triggerContract = useCallback(
    async ({
      contractAddress,
      triggerMethod,
      params,
      address,
      amount,
    }: TriggerContractProps) => {
      const defaultFeeLimit = { feeLimit: 400_000_000 };
      if (!window?.tronWeb) {
        return;
      }

      const contract = await window?.tronWeb?.contract().at(contractAddress);
      // Methods that need shouldPollResponse
      switch (triggerMethod) {
        case 'increaseAllowance':
          return await contract.increaseAllowance(address, amount).send({
            feeLimit: defaultFeeLimit.feeLimit,
            shouldPollResponse: false,
          });
        case 'marketBuy':
          return await contract.marketBuy(amount).send({
            feeLimit: defaultFeeLimit.feeLimit,
            shouldPollResponse: false,
          });
        default:
          break;
      }

      const method = contracts[triggerMethod];

      if (!params) {
        if (triggerMethod === 'paused') {
          const { constant_result } =
            await window?.tronWeb?.transactionBuilder.triggerSmartContract(
              contractAddress,
              method,
              { ...defaultFeeLimit },
            );
          return parseInt(constant_result[0], 16) === 1;
        }
        return;
      }
      const contractParameters = buildParams(params);

      const transaction =
        await window?.tronWeb?.transactionBuilder.triggerSmartContract(
          contractAddress,
          method,
          { ...defaultFeeLimit },
          contractParameters,
        );

      return parseTronResponse({ method: triggerMethod, transaction });
    },
    [],
  );

  const toggleConnect = () => {
    setCanConnect(true);
  };

  const changeAddress = () => {
    setAddress(window?.tronWeb?.defaultAddress?.base58);
  };

  const sign = ({ data }: SignProps) => {
    return window?.tronWeb?.trx.sign(data);
  };

  const haveAddress = useCallback(() => {
    if (!window?.tronWeb) {
      return false;
    }

    return !!window?.tronWeb?.defaultAddress?.base58;
  }, []);

  const updateAmount = useCallback(
    async address => {
      if (!window?.tronWeb) {
        return;
      }

      const params: BalanceOfParams = {
        account: {
          type: 'address',
          value: address,
        },
      };

      const newAmount = await triggerContract({
        triggerMethod: 'balanceOf',
        params,
        contractAddress: DVK.coin,
      });

      setAmount(newAmount);
    },
    [DVK.coin, triggerContract],
  );

  useEffect(() => {
    if (!isLoggedIn || address) {
      return;
    }
    window?.tronWeb?.on('addressChanged', ({ base58 }: INewAddress) => {
      if (base58 === address) {
        return;
      }

      setAddress(base58);
      updateAmount(base58);
    });
  }, [isLoggedIn, address, updateAmount]);

  useEffect(() => {
    if (!canConnect) {
      return;
    }

    if (address && address.length > 0) {
      const fetchToken = async () => {
        try {
          const nonce = generateNonce();

          let response = await promiseTimeout(10000, sign({ data: nonce }));
          if (response === 'rejected') {
            throw new Error(ErrTimeout);
          }

          if (response) {
            await signIn({ signature: response, address, nonce });
            setAddress(address);
            setisLoggedIn(true);
            await updateAmount(address);
          }
        } catch (error) {
          setCanConnect(false);

          switch (error) {
            case ErrTimeout:
              addToast({
                type: 'error',
                title: 'Login Error',
                description: "Can't login to serve due the reject signature",
              });
              break;
            case ErrUserRefusedToSign:
              addToast({
                type: 'error',
                title: 'Signature error',
                description: 'Timeout in signature modal',
              });
              break;
            default:
              break;
          }
        }
      };

      fetchToken();
    }
  }, [address, canConnect, addToast, signIn, updateAmount]);

  const getDvkAmount = async (address: string) => {
    if (!window?.tronWeb) {
      return;
    }

    const params: BalanceOfParams = {
      account: {
        type: 'address',
        value: address,
      },
    };

    const newAmount = await triggerContract({
      triggerMethod: 'balanceOf',
      params,
      contractAddress: DVK.coin,
    });

    return newAmount;
  };

  const allowance = async ({ owner }: AllowanceProps) => {
    if (!window?.tronWeb || !DVK.market || !DVK.coin) {
      return;
    }
    const params: AllowanceParams = {
      owner: {
        type: 'address',
        value: owner,
      },
      spender: {
        type: 'address',
        value: DVK.market,
      },
    };

    return await triggerContract({
      triggerMethod: 'allowance',
      contractAddress: DVK.coin,
      params,
    });
  };

  const getTrxAmount = async () => {
    if (!window?.tronWeb) {
      return;
    }

    const amount = await window?.tronWeb?.trx.getBalance(address);

    return amount / 10 ** 6;
  };

  const getTx = async (txID: string) => {
    if (!window?.tronWeb) {
      return;
    }

    const response = await window?.tronWeb?.trx.getTransaction(txID);

    return response;
  };

  const getMinterResources = async () => {
    if (!window?.tronWeb) {
      return;
    }

    let resources = { energy: 0, bandwidth: 0 };

    const response = await window?.tronWeb?.trx.getAccountResources(
      REACT_APP_HARDMINT_CONTRACT,
    );

    if (!!response.EnergyLimit) {
      if (!!response.EnergyUsed) {
        resources.energy = response.EnergyLimit - response.EnergyUsed;
      } else {
        resources.energy = response.EnergyLimit;
      }
    }

    if (!!response.NetLimit) {
      if (!!response.NetUsed) {
        resources.bandwidth = response.NetLimit - response.NetUsed;
      } else {
        resources.bandwidth = response.NetLimit;
      }
    }

    return resources;
  };

  const getNFTContractResources = async () => {
    if (!window?.tronWeb) {
      return;
    }

    let resources = { energy: 0, bandwidth: 0 };

    const response = await window?.tronWeb?.trx.getAccountResources(
      address,
    );

    console.log(address)
    console.log(response)

    if (!!response.EnergyLimit) {
      if (!!response.EnergyUsed) {
        resources.energy = response.EnergyLimit - response.EnergyUsed;
      } else {
        resources.energy = response.EnergyLimit;
      }
    }

    if (!!response.NetLimit) {
      if (!!response.NetUsed) {
        resources.bandwidth = response.NetLimit - response.NetUsed;
      } else {
        resources.bandwidth = response.NetLimit;
      }
    }

    console.log(resources)
    return resources;
  };

  const getResources = async () => {
    if (!window?.tronWeb) {
      return;
    }

    let resources = { energy: 0, bandwidth: 0 };

    const response = await window?.tronWeb?.trx.getAccountResources(address);

    if (!!response.EnergyLimit) {
      if (!!response.EnergyUsed) {
        resources.energy = response.EnergyLimit - response.EnergyUsed;
      } else {
        resources.energy = response.EnergyLimit;
      }
    }

    if (!!response.freeNetLimit) {
      if (!!response.freeNetUsed) {
        resources.bandwidth = response.freeNetLimit - response.freeNetUsed;
      } else {
        resources.bandwidth = response.freeNetLimit;
      }
    }

    return resources;
  };

  const increaseAllowance = async (amount: string) => {
    if (!DVK?.market || !window?.tronWeb) {
      return;
    }

    return await triggerContract({
      contractAddress: DVK.coin,
      triggerMethod: 'increaseAllowance',
      address: DVK.market,
      amount,
    });
  };

  const marketBuy = async (amount: string) => {
    if (!DVK?.market || !window?.tronWeb) {
      return;
    }

    return await triggerContract({
      contractAddress: DVK.market,
      triggerMethod: 'marketBuy',
      amount,
    });
  };

  const isMarketPaused = async () => {
    if (!DVK.market || !window?.tronWeb) {
      return;
    }

    return await triggerContract({
      contractAddress: DVK.market,
      triggerMethod: 'paused',
    });
  };

  const transferWithMessage = async (
    address: string,
    amount: string,
    message: string,
  ) => {
    if (!window?.tronWeb) {
      return;
    }

    const from = window?.tronWeb?.defaultAddress?.base58;

    const tx = await window?.tronWeb?.transactionBuilder.sendTrx(
      address,
      amount,
      from,
    );

    tx.raw_data.data = window?.tronWeb?.toHex(message);

    const signedTX = await window?.tronWeb?.trx.sign(tx);

    return await window?.tronWeb?.trx.sendRawTransaction(signedTX);
  };

  const transferFrom = async (toAddress: string, tokenID: Array<number>) => {
    if (!window?.tronWeb) {
      return;
    }
    const fromAddress = window?.tronWeb?.defaultAddress?.base58;
    const results = {
      errors: [] as number[],
      success: [] as number[],
      nftListLength: 0,
    };
    const contract = await window?.tronWeb
      .contract()
      .at('THjYwnDDN6aYxrzKb88CSMTEYjBuHpoYxS');

    for (let index = 0; index < tokenID?.length; index++) {
      try {
        const data = await contract
          .transferFrom(fromAddress, toAddress, tokenID[index])
          .send();
        if (data) {
          results.success.push(tokenID[index]);
        }
      } catch (error) {
        results.errors.push(tokenID[index]);
        if (typeof error === 'string') {
          addToast({
            type: 'error',
            title: 'Withdrawal error',
            description: `${error}`,
          });
        } else {
          addToast({
            type: 'error',
            title: 'Withdrawal error',
            description: 'Error when making the withdrawal',
          });
        }
      }
    }

    if (results.success?.length > 0) {
      addToast({
        type: 'success',
        title: 'Successful withdrawal!',
        description: ` NFT${results.success?.length > 1 ? 's' : ''
          } ${results.success?.toString()} have been withdrawal successfully. It may take a few minutes until the transaction is confirmed.`,
      });
    }
    return results;
  };

  const transferAssetWithMessage = async (
    contractAddress = DVK.coin,
    address: string,
    amount: string,
    message: string,
  ) => {
    if (!window?.tronWeb) {
      return;
    }

    const params: TransferParams = {
      recipient: {
        type: 'address',
        value: address,
      },
      amount: {
        type: 'uint256',
        value: amount,
      },
    };

    const tx = await triggerContract({
      contractAddress: contractAddress,
      triggerMethod: 'transfer',
      params,
    });

    let raw;
    if (navigator.userAgent.includes('TronWallet')) {
      raw = await window?.tronWeb?.transactionBuilder.addUpdateData(
        tx.transaction,
        message,
        'utf8',
      );
    } else {
      tx.transaction.raw_data.data = window?.tronWeb?.toHex(message);
      raw = tx.transaction;
    }

    const signedTX = await window?.tronWeb?.trx.sign(raw);

    return await window?.tronWeb?.trx.sendRawTransaction(signedTX);
  };

  const providerProps = {
    value: {
      address,
      amount,
      isInstalled,
      isLoggedIn,
      canConnect,
      toggleConnect,
      changeAddress,
      sign,
      getTrxAmount,
      getTx,
      isMarketPaused,
      getResources,
      getMinterResources,
      getNFTContractResources,
      getDvkAmount,
      haveAddress,
      updateAmount,
      increaseAllowance,
      marketBuy,
      allowance,
      transferWithMessage,
      transferAssetWithMessage,
      transferFrom,
    },
  };

  return (
    <TronContext.Provider {...providerProps}>{children}</TronContext.Provider>
  );
};

const useTron = () => {
  const context = useContext(TronContext);

  return context;
};

export { TronProvider, useTron };
