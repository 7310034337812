import styled from 'styled-components';

export const Container = styled.div`
  color: ${props => props.theme.white};
`;

export const TitleContainer = styled.section`
  padding: 5rem 0 0 0;

  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;

  color: ${props => props.theme.text};

  border-bottom: 1px solid ${props => props.theme.header.border};

  p {
    margin: 2.5rem 0;

    color: ${props => props.theme.text};
    font-weight: 400;

    opacity: 0.7;
  }

  @media (max-width: 768px) {
    padding: 5rem 1.5rem;

    p {
      text-align: center;
      line-height: 1.75rem;
    }
  }
`;

export const TitleContent = styled.span`
  max-width: 32rem;

  text-align: center;
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 3rem;

  div {
    display: contents;

    font-weight: 500;

    color: ${props => props.theme.primary};
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const ListContainer = styled.section`
  margin: 0 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1200px) {
    margin: 0 7.5rem;
  }

  @media (max-width: 1024px) {
    margin: 0 1.5rem;
  }
`;

export const ListTitle = styled.div`
  margin: 2.5rem 0 2.5rem 0;

  width: 100%;

  display: flex;

  justify-content: center;

  h3 {
    color: ${props => props.theme.text};
    font-size: 1.875rem;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    h3 {
      text-align: center;
    }
  }
`;

export const ListContent = styled.div`
  display: grid;

  grid-template-columns: repeat(4, 16rem);
  grid-gap: 2rem;

  @media (max-width: 1440px) {
    grid-template-columns: repeat(3, 28vw);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 44vw);
  }

  @media (max-width: 492px) {
    grid-template-columns: 90vw;
  }
`;

export const ButtonContainer = styled.section`
  margin: 2.5rem 0 3.5rem 0;

  display: flex;

  align-items: center;
  justify-content: center;
`;

export const RenderStatus = styled.div`
  display: flex;

  flex-direction: row !important;

  gap: 1rem;

  flex-wrap: wrap;

  justify-content: center;

  div {
    display: flex;

    flex-direction: column;

    align-items: center;

    gap: 0.25rem;
  }
  .common {
    border: 1px solid #875b27;
  }
  .uncommon {
    border: 1px solid #eaeaea;
  }
  .rare {
    border: 1px solid #dac504;
  }
  .mythic {
    border: 1px solid #d70606;
  }
  .eldritchCount {
    border: 1px solid #cd00d8;
  }
`;

export const ItemStatus = styled.div`
  margin: 1rem 0;
  padding : 8px;
  display: flex;

  align-items: center;
  justify-content: center;
  flex-direction: column; !important;

  min-width:150px;

  background: ${props => props.theme.background};
  color: ${props => props.theme.text};
  box-sizing: border-box;
  border-radius: 8px;

  .div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; !important;
  }
`;

export const CollectionStats = styled.div`
  margin: 1rem 0;
  padding : 8px;
  display: flex;

  align-items: center;
  justify-content: center;
  flex-direction: column; !important;

  .title{
    font-size: 24px;
    line-height: 29px;
    font-weight: bold;
    padding : 8px;
    color: ${props => props.theme.text};
  }
  .subtitle{
    color: ${props => props.theme.text};
  }
  .div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; !important;
    color: ${props => props.theme.text};
  }
`;
